import { SortDirection } from '@/model/enums/sort-direction';

export default class PageOptions {
  public page: number;
  public size: number;
  public sort: string;

  constructor(page: number = 0, size: number = 10, sort: string = '', isDesc: boolean = false) {
    this.page = page;
    this.size = size;
    this.sort = '';
    this.setSort(sort, isDesc);
  }

  public setSort(sort: string, isDesc: boolean = false) {
    this.sort = sort ? `${sort},${isDesc ? SortDirection.DESC : SortDirection.ASC}` : '';
  }
}
