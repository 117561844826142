import axios from 'axios';
import { Commit, Dispatch } from 'vuex';
import Deliverer from '@/model/deliverer';
import Pageable from '@/model/page/pageable';
import PageOptions from '@/model/page/page-options';
import { HttpStatus } from '@/model/enums/http-status';
import { i18n } from '@/i18n';
import { Composer } from 'vue-i18n';
import { DelivererRequest } from '@/model/request/deliverer-request';

const { t }: Composer = i18n.global;

export default {
  namespaced: true,
  state: {
    pageableDeliverers: {} as Pageable<Deliverer>,
    operationalUnitDeliverers: {} as Pageable<Deliverer>,
    availableOperationalUnitDeliverers: {} as Pageable<Deliverer>,
  },
  mutations: {
    setPageableDeliverers(state: { pageableDeliverers: Pageable<Deliverer> }, payload: Pageable<Deliverer>) {
      if (payload.currentPage === 0) {
        state.pageableDeliverers = payload;
      } else {
        state.pageableDeliverers.items = state.pageableDeliverers.items.concat(payload.items);
        state.pageableDeliverers.currentPage = payload.currentPage;
      }
    },
    setOperationalUnitDeliverers(
      state: { operationalUnitDeliverers: Pageable<Deliverer> },
      payload: Pageable<Deliverer>,
    ) {
      if (payload.currentPage === 0) {
        state.operationalUnitDeliverers = payload;
      } else {
        state.operationalUnitDeliverers.items = state.operationalUnitDeliverers.items.concat(payload.items);
        state.operationalUnitDeliverers.currentPage = payload.currentPage;
      }
    },
    setAvailableOperationalUnitDeliverers(
      state: { availableOperationalUnitDeliverers: Pageable<Deliverer> },
      payload: Pageable<Deliverer>,
    ) {
      if (payload.currentPage === 0) {
        state.availableOperationalUnitDeliverers = payload;
      } else {
        state.availableOperationalUnitDeliverers.items = state.availableOperationalUnitDeliverers.items.concat(
          payload.items,
        );
        state.availableOperationalUnitDeliverers.currentPage = payload.currentPage;
      }
    },
    setDeliverer(
      state: { pageableDeliverers: Pageable<Deliverer>; operationalUnitDeliverers: Pageable<Deliverer> },
      payload: Deliverer,
    ) {
      const pageableDeliverers = new Pageable<Deliverer>(state.pageableDeliverers);
      const operationalUnitDeliverers = new Pageable<Deliverer>(state.operationalUnitDeliverers);
      if (pageableDeliverers.items) {
        if (pageableDeliverers.getById(payload.id)) {
          pageableDeliverers.setById(payload.id, payload);
        } else {
          pageableDeliverers.addItem(payload);
        }
        state.pageableDeliverers = pageableDeliverers;
      }
      if (operationalUnitDeliverers.items && operationalUnitDeliverers.getById(payload.id)) {
        operationalUnitDeliverers.setById(payload.id, payload);
        state.operationalUnitDeliverers = operationalUnitDeliverers;
      }
    },
    removeDeliverer(state: { pageableDeliverers: Pageable<Deliverer> }, id: number) {
      const pageableDeliverers = new Pageable<Deliverer>(state.pageableDeliverers);
      pageableDeliverers.removeById(id);
    },
    addOperationalUnitDeliverer(
      state: { availableOperationalUnitDeliverers: Pageable<Deliverer> },
      payload: Deliverer,
    ) {
      const pageableDeliverers = new Pageable<Deliverer>(state.availableOperationalUnitDeliverers);
      pageableDeliverers.removeById(payload.id);
    },
    removeOperationalUnitDeliverer(state: { operationalUnitDeliverers: Pageable<Deliverer> }, payload: number) {
      const operationalUnitDeliverers = new Pageable<Deliverer>(state.operationalUnitDeliverers);
      operationalUnitDeliverers.removeById(payload);
      state.operationalUnitDeliverers = operationalUnitDeliverers;
    },
  },
  actions: {
    getAllDelivererPage(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { text, pageOptions }: { text: string; pageOptions: PageOptions },
    ) {
      return axios
        .get(`/deliverers/page`, { params: { ...pageOptions, text } })
        .then(({ data }) => {
          if (data) {
            commit('setPageableDeliverers', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getOperationalUnitDelivererPage(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { operationalUnitId, text, pageOptions }: { operationalUnitId: number; text: string; pageOptions: PageOptions },
    ) {
      return axios
        .get(`/operational-units/${operationalUnitId}/deliverers`, { params: { ...pageOptions, text } })
        .then(({ data }) => {
          if (data) {
            commit('setOperationalUnitDeliverers', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getAvailableDeliverersForOperationalUnit(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { operationalUnitId, text, pageOptions }: { operationalUnitId: number; text: string; pageOptions: PageOptions },
    ) {
      return axios
        .get(`deliverers/operational-units/${operationalUnitId}`, { params: { ...pageOptions, text } })
        .then(({ data }) => {
          if (data) {
            commit('setAvailableOperationalUnitDeliverers', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    save({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: DelivererRequest) {
      return axios
        .post('/deliverers', request)
        .then((response) => {
          if (response.status !== HttpStatus.OK_BUT_NOT_FILTERED) {
            commit('setDeliverer', response.data);
          }
          dispatch(
            'showSuccessNotification',
            { message: t('notification.success.deliverer-addition') },
            { root: true },
          );
          return response.data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modify({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: DelivererRequest) {
      return axios
        .put(`/deliverers/${request.id}`, request)
        .then(({ data }) => {
          commit('setDeliverer', data);
          dispatch(
            'showSuccessNotification',
            {
              message: t('notification.success.deliverer-modification', {
                name: data.name,
              }),
            },
            { root: true },
          );
          return data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, deliverer: Deliverer) {
      return axios
        .delete(`/deliverers/${deliverer.id}`)
        .then(() => {
          commit('removeDeliverer', deliverer.id);
          dispatch(
            'showSuccessNotification',
            {
              message: t('notification.success.deliverer-deletion', {
                name: deliverer.name,
              }),
            },
            { root: true },
          );
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    addOperationalUnitDeliverer(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { operationalUnitId, deliverer }: { operationalUnitId: number; deliverer: Deliverer },
    ) {
      return axios
        .post(`/operational-units/${operationalUnitId}/deliverers/${deliverer.id}`)
        .then(() => {
          commit('addOperationalUnitDeliverer', deliverer);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    removeOperationalUnitDeliverer(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { operationalUniId, delivererId }: { operationalUniId: number; delivererId: number },
    ) {
      return axios
        .delete(`/operational-units/${operationalUniId}/deliverers/${delivererId}`)
        .then(() => {
          commit('removeOperationalUnitDeliverer', delivererId);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getPageableDeliverers(state: { pageableDeliverers: Pageable<Deliverer> }) {
      return state.pageableDeliverers;
    },
    getOperationalUnitDeliverers(state: { operationalUnitDeliverers: Pageable<Deliverer> }) {
      return state.operationalUnitDeliverers;
    },
    getAvailableOperationalUnitDeliverers(state: { availableOperationalUnitDeliverers: Pageable<Deliverer> }) {
      return state.availableOperationalUnitDeliverers;
    },
  },
};
