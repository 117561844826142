import axios from 'axios';
import UserDetails from '@/model/user-details';
import PageOptions from '@/model/page/page-options';
import Pageable from '@/model/page/pageable';
import Notification from '@/model/notification';
import { i18n } from '@/i18n';
import { Composer } from 'vue-i18n';
import AdminUserFilterRequest from '@/model/request/admin-user-filter-request';

const { t }: Composer = i18n.global;

export default {
  namespaced: true,
  state: {
    users: {} as Pageable<UserDetails>,
  },
  mutations: {
    setUsers(state: { users: Pageable<UserDetails> }, payload: Pageable<UserDetails>) {
      state.users = payload;
    },
    setModifiedUser(state: { users: Pageable<UserDetails> }, payload: UserDetails) {
      const pageableUsers = new Pageable<UserDetails>(state.users);
      if (pageableUsers.getById(payload.id)) {
        pageableUsers.setById(payload.id, payload);
      } else {
        pageableUsers.items.push(payload);
      }
    },
  },
  actions: {
    getUsersPage({ commit, dispatch, rootGetters }: any, pageOptions: PageOptions) {
      const searchText = rootGetters['filterStorage/getAdminUsersSearchText'];
      const request = new AdminUserFilterRequest(searchText);
      return axios
        .get('/user', { params: { ...pageOptions, ...request }})
        .then(({ data }) => {
          if (data) {
            commit('setUsers', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    save({ commit, dispatch }: any, user: UserDetails) {
      return axios
        .post('/user', user)
        .then(({ data }) => {
          if (data) {
            commit('setModifiedUser', data);
            commit('currentUserStorage/setUserDetails', data, { root: true });
            if (user.id) {
              dispatch(
                'showSuccessNotification',
                {
                  message: t('admin.user-settings.modify.success', { name: `${data.lastName} ${data.firstName}` }),
                },
                { root: true },
              );
            } else {
              dispatch(
                'showSuccessNotification',
                { message: t('admin.user-settings.add.success', { name: `${data.lastName} ${data.firstName}` }) },
                { root: true },
              );
            }
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete({ dispatch, rootGetters }: any, id: number) {
      return axios
        .delete(`/user/${id}`)
        .then(({ data }) => {
          if (data) {
            dispatch(
              'showSuccessNotification',
              new Notification(t('admin.user-settings.delete.success').toString()),
              { root: true },
            ).then((confirmed: any) => {
              if (confirmed && rootGetters['currentUserStorage/getCurrentUser']?.id === id) {
                dispatch('currentUserStorage/logout');
              }
            });
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getUsers(state: { users: Pageable<UserDetails> }) {
      return state.users;
    },
  },
};
