import { App } from 'vue';
import Swal from 'sweetalert2';

const sweetAlert = {
    install(app: App) {
        app.config.globalProperties.$swal = Swal;
        app.provide('swal', Swal);
    },
};

export default sweetAlert;