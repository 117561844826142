export const acceptedFileTypes = [
  'audio/',
  'image/',
  'video/',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/msword',
  'application/vnd.ms-powerpoint',
  'application/pdf',
  'application/vnd.ms-excel',
  'text/csv',
  'text/plain',
];

export const acceptedPictureTypes = ['image/png', 'image/jpg', 'image/jpeg'];
