import SortedChangelog from '@/model/sorted-changelog';
import axios from 'axios';

export default {
  namespaced: true,
  state: {
    requirementChangelogs: {} as SortedChangelog,
    documentContainerChangelogs: {} as SortedChangelog,
  },
  mutations: {
    setRequirementChangelogs(state: { requirementChangelogs: SortedChangelog }, payload: SortedChangelog) {
      state.requirementChangelogs = payload;
    },
    setDocumentContainerChangelogs(state: { documentContainerChangelogs: SortedChangelog }, payload: SortedChangelog) {
      state.documentContainerChangelogs = payload;
    },
  },
  actions: {
    getRequirementChangelogs({ commit, dispatch }: any, requirementId: number) {
      return axios
        .get(`/changelogs/requirement/${requirementId}`)
        .then(({ data }) => {
          if (data) {
            commit('setRequirementChangelogs', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getDocumentContainerChangelogs({ commit, dispatch }: any, containerId: number) {
      return axios
        .get(`/changelogs/container/${containerId}`)
        .then(({ data }) => {
          if (data) {
            commit('setDocumentContainerChangelogs', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getRequirementChangelogs(state: { requirementChangelogs: SortedChangelog }) {
      return state.requirementChangelogs;
    },
    getDocumentContainerChangelogs(state: { documentContainerChangelogs: SortedChangelog }) {
      return state.documentContainerChangelogs;
    },
  },
};
