export const LONG_DEFAULT_DATE_FORMAT = 'YYYY. MMMM DD.';

export const SHORT_DEFAULT_DATE_FORMAT = 'YYYY. MMM D.';

export const DEFAULT_DATE_TIME_FORMAT = 'YYYY. MMM DD. - HH:mm';

export const PICKER_DATE_FORMAT = 'YYYY-MM-DD';

export const NUMBER_DATE_FORMAT = 'YYYY. MM. DD.';

export const FILTER_DATE_FORMAT = 'YYYY-MM-DD';
