import { State } from '@/model/enums/state';
import { acceptedFileTypes } from '@/constants/accepted-file-types';

export const calculatorMixin = {
  methods: {
    getDocumentState(startDate: Date, endDate: Date): State {
      let state = State.OK;
      if (!endDate) {
        state = State.NOT_SET;
      } else if (Date.now() > new Date(endDate).valueOf()) {
        state = State.EXPIRED;
      } else if (
        Date.now() >
        new Date(startDate).valueOf() + ((new Date(endDate).valueOf() - new Date(startDate).valueOf()) * 70) / 100
      ) {
        state = State.NEAR;
      }
      return state;
    },
    statusClass(state: State): string {
      return state === State.OK ? 'ok' : state === State.NEAR ? 'near' : state === State.EXPIRED ? 'expired' : '';
    },
    checkSupportedType(file: File): boolean {
      return (
        acceptedFileTypes.includes(file.type) ||
        file.type.startsWith('image/') ||
        file.type.startsWith('audio/') ||
        file.type.startsWith('video/') ||
        file.type === ''
      );
    },
  },
};
