import { i18n } from '@/i18n';
import { Composer } from 'vue-i18n';
import Pageable from '@/model/page/pageable';
import BasicInformationRequest from '@/model/request/basic-information-request';
import WorkField from '@/model/work-field';
import Notification from '@/model/notification';
import axios from 'axios';
import { Commit, Dispatch } from 'vuex';
import PageOptions from '@/model/page/page-options';
import AdminBasicInformationOptionsFilterRequest from '@/model/request/admin-basic-information-options-filter-request';

const { t }: Composer = i18n.global;

export default {
  namespaced: true,
  state: {
    workFields: new Array<WorkField>(),
    workFieldPageable: {} as Pageable<WorkField>,
  },
  mutations: {
    setWorkFields(state: { workFields: WorkField[] }, payload: WorkField[]) {
      state.workFields = payload;
    },
    setWorkFieldPageable(state: { workFieldPageable: Pageable<WorkField> }, payload: Pageable<WorkField>) {
      state.workFieldPageable = payload;
    },
    setModifiedWorkField(state: { workFieldPageable: Pageable<WorkField> }, payload: WorkField) {
      const index = state.workFieldPageable.items.findIndex((workField: WorkField) => workField.id === payload.id);
      if (index >= 0) {
        Object.assign(state.workFieldPageable.items[index], payload);
      } else {
        state.workFieldPageable.items.push(payload);
      }
    },
    removeWorkfield(state: { workFieldPageable: Pageable<WorkField> }, payload: number) {
      const pageableWorkFields = new Pageable<WorkField>(state.workFieldPageable);
      pageableWorkFields.removeById(payload);
    },
  },
  actions: {
    getWorkFields({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
      return axios
        .get('/work-fields')
        .then(({ data }) => {
          if (data) {
            commit('setWorkFields', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getPageableWorkFields(
      { commit, dispatch, rootGetters }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
      pageOptions: PageOptions,
    ) {
      const searchText = rootGetters['filterStorage/getAdminBasicInformationOptionsSearchText'];
      const request = new AdminBasicInformationOptionsFilterRequest(searchText);
      return axios
        .get('/work-fields/pageable', { params: { ...pageOptions, ...request } })
        .then(({ data }) => {
          if (data) {
            commit('setWorkFieldPageable', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    add({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: BasicInformationRequest) {
      return axios
        .post('/work-fields', request)
        .then(({ data }) => {
          if (data) {
            commit('setModifiedWorkField', data);
            dispatch(
              'showSuccessNotification',
              { message: t('admin.basic-information-settings.workfield.add.success', { name: data.name }) },
              { root: true },
            );
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modify({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: BasicInformationRequest) {
      return axios
        .put('/work-fields', request)
        .then(({ data }) => {
          if (data) {
            commit('setModifiedWorkField', data);
            dispatch(
              'showSuccessNotification',
              {
                message: t('admin.basic-information-settings.workfield.modify.success', { name: data.name }),
              },
              { root: true },
            );
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, id: number) {
      return axios
        .delete(`/work-fields/${id}`)
        .then(() => {
          commit('removeWorkfield', id);
          dispatch(
            'showSuccessNotification',
            new Notification(t('admin.basic-information-settings.workfield.delete.success').toString()),
            { root: true },
          );
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getWorkFields(state: { workFields: WorkField[] }) {
      return state.workFields;
    },
    getPageableWorkFields(state: { workFieldPageable: Pageable<WorkField> }) {
      return state.workFieldPageable;
    },
  },
};
