import { i18n } from '@/i18n';
import { Composer } from 'vue-i18n';

const { t }: Composer = i18n.global;

export const validationErrorsMixin = {
  methods: {
    lastNameError(submitted: boolean, validationField: any): string {
      return submitted && validationField.required.$invalid
        ? t('basic-information.people.form.last-name-required').toString()
        : submitted && validationField.maxLength.$invalid
        ? t('basic-information.people.form.last-name-max-length').toString()
        : '';
    },
    firstNameError(submitted: boolean, validationField: any): string {
      return submitted && validationField.required.$invalid
        ? t('basic-information.people.form.first-name-required').toString()
        : submitted && validationField.maxLength.$invalid
        ? t('basic-information.people.form.first-name-max-length').toString()
        : '';
    },
    workFieldError(submitted: boolean, validationField: any): string {
      return submitted && validationField.notEmptyList.$invalid
        ? t('basic-information.people.form.work-field-not-empty').toString()
        : '';
    },
    innerIdError(submitted: boolean, validationField: any): string {
      return submitted && validationField.required.$invalid
        ? t('basic-information.people.form.inner-id-required').toString()
        : submitted && validationField.maxLength.$invalid
        ? t('basic-information.people.form.inner-id-max-length').toString()
        : '';
    },
    commentError(submitted: boolean, validationField: any): string {
      return submitted && validationField.maxLength.$invalid
        ? t('basic-information.validation.comment-max-length').toString()
        : '';
    },
    qualificationError(submitted: boolean, validationField: any): string {
      return submitted && validationField.notEmptyList.$invalid
        ? t('basic-information.people.form.qualification-not-empty').toString()
        : '';
    },
    roleError(submitted: boolean, validationField: any): string {
      return submitted && validationField.notEmptyList.$invalid
        ? t('basic-information.people.form.role-not-empty').toString()
        : '';
    },
    educationDateError(submitted: boolean, validationField: any): string {
      return submitted && validationField.required.$invalid
        ? t('basic-information.people.form.education-date-required').toString()
        : '';
    },
    organizationUnitError(submitted: boolean, validationField: any): string {
      return submitted && validationField.maxLength.$invalid
        ? t('basic-information.validation.organization-unit-max-length').toString()
        : '';
    },
    locationCodeError(submitted: boolean, validationField: any): string {
      return submitted && validationField.maxLength.$invalid
        ? t('basic-information.validation.location-code-max-length').toString()
        : '';
    },
    categoryError(submitted: boolean, validationField: any): string {
      return submitted && validationField.required.$invalid
        ? t('basic-information.tool.form.category-required').toString()
        : '';
    },
    idError(submitted: boolean, validationField: any): string {
      return submitted && validationField.required.$invalid
        ? t('basic-information.tool.form.id-required').toString()
        : submitted && validationField.maxLength.$invalid
        ? t('basic-information.tool.form.id-max-length').toString()
        : '';
    },
    dangerRateError(submitted: boolean, validationField: any): string {
      return submitted && validationField.required.$invalid
        ? t('basic-information.tool.form.danger-rate-required').toString()
        : '';
    },
    nameError(submitted: boolean, validationField: any): string {
      return submitted && validationField.required.$invalid
        ? t('basic-information.validation.name-required').toString()
        : submitted && validationField.maxLength.$invalid
        ? t('basic-information.validation.name-max-length').toString()
        : '';
    },
    areaError(submitted: boolean, validationField: any): string {
      return submitted && validationField.required.$invalid
        ? t('basic-information.place.form.area-required').toString()
        : submitted && validationField.minValue.$invalid
        ? t('basic-information.place.form.area-min').toString()
        : '';
    },
    coordIdError(submitted: boolean, validationField: any): string {
      return submitted && validationField.required.$invalid
        ? t('basic-information.place.form.coord-id-required').toString()
        : submitted && validationField.maxLength.$invalid
        ? t('basic-information.place.form.coord-id-max-length').toString()
        : '';
    },
    placeTraitError(submitted: boolean, validationField: any): string {
      return submitted && validationField.required.$invalid
        ? t('basic-information.place.form.place-trait-required').toString()
        : '';
    },
    typeError(submitted: boolean, validationField: any): string {
      return submitted && validationField.required.$invalid
        ? t('basic-information.place.form.type-required').toString()
        : '';
    },
  },
};
