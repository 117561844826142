import axios from 'axios';
import { Commit, Dispatch } from 'vuex';
import Protector from '@/model/protector';
import Pageable from '@/model/page/pageable';
import { i18n } from '@/i18n';
import { Composer } from 'vue-i18n';
import BasicInformationRequest from '@/model/request/basic-information-request';
import Notification from '@/model/notification';
import PageOptions from '@/model/page/page-options';
import AdminBasicInformationOptionsFilterRequest from '@/model/request/admin-basic-information-options-filter-request';

const { t }: Composer = i18n.global;

export default {
  namespaced: true,
  state: {
    protectors: new Array<Protector>(),
    protectorPageable: {} as Pageable<Protector>,
  },
  mutations: {
    setProtectors(state: { protectors: Protector[] }, payload: Protector[]) {
      state.protectors = payload;
    },
    setProtectorPageable(state: { protectorPageable: Pageable<Protector> }, payload: Pageable<Protector>) {
      state.protectorPageable = payload;
    },
    setModifiedProtector(state: { protectorPageable: Pageable<Protector> }, payload: Protector) {
      const index = state.protectorPageable.items.findIndex((protector: Protector) => protector.id === payload.id);
      if (index >= 0) {
        Object.assign(state.protectorPageable.items[index], payload);
      } else {
        state.protectorPageable.items.push(payload);
      }
    },
    removeProtector(state: { protectorPageable: Pageable<Protector> }, payload: number) {
      const pageableProtectors = new Pageable<Protector>(state.protectorPageable);
      pageableProtectors.removeById(payload);
    },
  },
  actions: {
    getProtectors({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
      return axios
        .get('/protectors')
        .then(({ data }) => {
          if (data) {
            commit('setProtectors', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getPageableProtectors(
      { commit, dispatch, rootGetters }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
      pageOptions: PageOptions,
    ) {
      const searchText = rootGetters['filterStorage/getAdminBasicInformationOptionsSearchText'];
      const request = new AdminBasicInformationOptionsFilterRequest(searchText);
      return axios
        .get('/protectors/pageable', { params: { ...pageOptions, ...request } })
        .then(({ data }) => {
          if (data) {
            commit('setProtectorPageable', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    add({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: BasicInformationRequest) {
      return axios
        .post('/protectors', request)
        .then(({ data }) => {
          if (data) {
            commit('setModifiedProtector', data);
            dispatch(
              'showSuccessNotification',
              { message: t('admin.basic-information-settings.protector.add.success', { name: data.name }) },
              { root: true },
            );
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modify({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: BasicInformationRequest) {
      return axios
        .put('/protectors', request)
        .then(({ data }) => {
          if (data) {
            commit('setModifiedProtector', data);
            dispatch(
              'showSuccessNotification',
              {
                message: t('admin.basic-information-settings.protector.modify.success', { name: data.name }),
              },
              { root: true },
            );
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, id: number) {
      return axios
        .delete(`/protectors/${id}`)
        .then(() => {
          commit('removeProtector', id);
          dispatch(
            'showSuccessNotification',
            new Notification(t('admin.basic-information-settings.protector.delete.success').toString()),
            { root: true },
          );
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getProtectors(state: { protectors: Protector[] }) {
      return state.protectors;
    },
    getPageableProtectors(state: { protectorPageable: Pageable<Protector> }) {
      return state.protectorPageable;
    },
  },
};
