import { PeriodicTerm } from '@/model/enums/periodic-term';

export default class PeriodicOption {
  public frequencyNumber: number | null;
  public term: PeriodicTerm | null;

  constructor(frequencyNumber: number | null, term: PeriodicTerm | null) {
    this.frequencyNumber = frequencyNumber;
    this.term = term;
  }
}
