import { Role } from '@/model/enums/role';
import { Route } from '@/model/enums/route';

export const authorizedRoles = [
  {
    pageName: Route.DASHBOARD,
    roles: [Role.EMPLOYEE, Role.ADMIN, Role.MANAGER],
  },
  {
    pageName: Route.DOCUMENTS,
    roles: [Role.EMPLOYEE, Role.ADMIN, Role.MANAGER, Role.GUEST],
  },
  {
    pageName: Route.DOCUMENTS,
    roles: [Role.EMPLOYEE, Role.ADMIN, Role.MANAGER, Role.GUEST],
  },
  {
    pageName: Route.BASIC_INFORMATION,
    roles: [Role.EMPLOYEE, Role.ADMIN, Role.MANAGER, Role.HR, Role.PROCUREMENT, Role.GUEST],
  },
  {
    pageName: Route.ORGANIZATIONAL_MAP,
    roles: [Role.EMPLOYEE, Role.ADMIN, Role.MANAGER, Role.HR, Role.PROCUREMENT, Role.GUEST],
  },
  {
    pageName: Route.USER_DETAILS,
    roles: [Role.EMPLOYEE, Role.ADMIN, Role.MANAGER, Role.HR, Role.PROCUREMENT, Role.GUEST],
  },
  {
    pageName: Route.ADMIN,
    roles: [Role.ADMIN],
  },
];
