import Comment from '@/model/comment';
import axios from 'axios';
import CommentRequest from '@/model/request/comment-request';

export default {
  namespaced: true,
  state: {
    requirementComments: new Array<Comment>(),
    documentContainerComments: new Array<Comment>(),
  },
  mutations: {
    setRequirementComments(state: { requirementComments: Comment[] }, payload: Comment[]) {
      state.requirementComments = payload;
    },
    setDocumentContainerComments(state: { documentContainerComments: Comment[] }, payload: Comment[]) {
      state.documentContainerComments = payload;
    },
    addRequirementComment(state: { requirementComments: Comment[] }, payload: Comment) {
      state.requirementComments.push(payload);
    },
    addDocumentContainerComment(state: { documentContainerComments: Comment[] }, payload: Comment) {
      state.documentContainerComments.push(payload);
    },
  },
  actions: {
    getRequirementComments({ commit, dispatch }: any, requirementId: number) {
      return axios
        .get(`/comments/requirement/${requirementId}`)
        .then(({ data }) => {
          if (data) {
            commit('setRequirementComments', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getDocumentContainerComments({ commit, dispatch }: any, containerId: number) {
      return axios
        .get(`/comments/document-container/${containerId}`)
        .then(({ data }) => {
          if (data) {
            commit('setDocumentContainerComments', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    sendRequirementComment(
      { commit, dispatch }: any,
      { requirementId, commentRequest }: { requirementId: number; commentRequest: CommentRequest },
    ) {
      return axios
        .post(`/comments/requirement/${requirementId}`, commentRequest)
        .then(({ data }) => {
          if (data) {
            commit('addRequirementComment', data);
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    sendDocumentContainerComment(
      { commit, dispatch }: any,
      { containerId, commentRequest }: { containerId: number; commentRequest: CommentRequest },
    ) {
      return axios
        .post(`/comments/document-container/${containerId}`, commentRequest)
        .then(({ data }) => {
          if (data) {
            commit('addDocumentContainerComment', data);
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getRequirementComments(state: { requirementComments: Comment[] }) {
      return state.requirementComments;
    },
    getDocumentContainerComments(state: { documentContainerComments: Comment[] }) {
      return state.documentContainerComments;
    },
  },
};
