import { ToolFilterOptions } from '@/store/modules/tool-filter-storage';

export default class ToolFilterRequest {
  public text: string;
  public dangerRates: string | undefined;
  public active: boolean | undefined;
  public operationalUnitId?: number;

  constructor(text: string, options: ToolFilterOptions) {
    this.text = text;

    this.dangerRates = options.dangerRates.length > 0 ? options.dangerRates?.join(',') : undefined;
    this.active = options.active;
  }

  public dangerRatesToArray(): {
    text: string;
    dangerRates: string[] | undefined;
    active: boolean | undefined;
    unitId?: number;
  } {
    return {
      ...this,
      dangerRates: this.dangerRates ? this.dangerRates.split(',') : undefined,
    };
  }
}
