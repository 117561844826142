import PersonFilterOptions from '@/model/filter/person-filter-options';
import PersonRole from '@/model/person-role';
import Protector from '@/model/protector';
import Qualification from '@/model/qualification';
import WorkField from '@/model/work-field';
import { Module } from 'vuex';

export const personFilterStorage: Module<PersonFilterOptions, unknown> = {
  namespaced: true,
  state: {
    state: undefined,
    roles: [],
    workfields: [],
    qualifications: [],
    individualProtectors: [],
    condition: undefined,
    educationText: '',
    medicalExaminationText: '',
  },
  mutations: {
    setState(state, payload: PersonFilterOptions['state']) {
      state.state = payload;
    },
    toggleRole(state, payload: PersonRole) {
      const index = state.roles.findIndex((roleId: number) => roleId === payload.id);

      if (index >= 0) {
        state.roles.splice(index, 1);
      } else {
        state.roles.push(payload.id);
      }
    },
    toggleWorkfield(state, payload: WorkField) {
      const index = state.workfields.findIndex((workFieldId: number) => workFieldId === payload.id);

      if (index >= 0) {
        state.workfields.splice(index, 1);
      } else {
        state.workfields.push(payload.id);
      }
    },
    toggleQualification(state, payload: Qualification) {
      const index = state.qualifications.findIndex((qualificationId: number) => qualificationId === payload.id);

      if (index >= 0) {
        state.qualifications.splice(index, 1);
      } else {
        state.qualifications.push(payload.id);
      }
    },
    toggleProtector(state, payload: Protector) {
      const index = state.individualProtectors.findIndex((protectorId: number) => protectorId === payload.id);

      if (index >= 0) {
        state.individualProtectors.splice(index, 1);
      } else {
        state.individualProtectors.push(payload.id);
      }
    },
    setCondition(state, payload: PersonFilterOptions['condition']) {
      state.condition = payload;
    },
    setEducationText(state, payload: PersonFilterOptions['educationText']) {
      state.educationText = payload;
    },
    setMedicalExaminationText(state, payload: PersonFilterOptions['medicalExaminationText']) {
      state.medicalExaminationText = payload;
    },
    setPersonFilterOptions(state, payload: PersonFilterOptions) {
      state.state = payload.state;
      state.roles?.splice(0);
      state.roles?.push(...payload.roles);
      state.workfields?.splice(0);
      state.workfields?.push(...payload.workfields);
      state.qualifications?.splice(0);
      state.qualifications?.push(...payload.qualifications);
      state.individualProtectors?.splice(0);
      state.individualProtectors?.push(...payload.qualifications);
      state.condition = payload.condition;
      state.educationText = payload.educationText;
      state.medicalExaminationText = payload.medicalExaminationText;
    },
    clearPersonFilterOptions(state) {
      state.state = undefined;
      state.roles = [];
      state.workfields = [];
      state.qualifications = [];
      state.individualProtectors = [];
      state.condition = undefined;
      state.educationText = '';
      state.medicalExaminationText = '';
    },
  },
  getters: {
    getPersonFilterOptions(state): PersonFilterOptions {
      return state;
    },
    isFiltering(state) {
      return (
        state.state !== undefined ||
        (state.roles && state.roles.length > 0) ||
        (state.workfields && state.workfields.length > 0) ||
        (state.qualifications && state.qualifications.length > 0) ||
        (state.individualProtectors && state.individualProtectors.length > 0) ||
        state.condition !== undefined ||
        state.educationText.length > 0 ||
        state.medicalExaminationText.length > 0
      );
    },
  },
};
