export enum Route {
  DASHBOARD = 'Dashboard',
  DOCUMENTS = 'Documents',
  BASIC_INFORMATION = 'BasicInformation',
  BASIC_INFORMATION_PLACES = 'BasicInformationPlaces',
  BASIC_INFORMATION_PEOPLE = 'BasicInformationPeople',
  BASIC_INFORMATION_TOOLS = 'BasicInformationTools',
  ORGANIZATIONAL_MAP = 'OrganizationalMap',
  USER_DETAILS = 'UserDetails',
  ADMIN = 'Admin',
  ADMIN_USERS = 'AdminUsers',
  ADMIN_TYPES = 'AdminTypes',
  ADMIN_REQUIREMENTS = 'AdminRequirements',
  ADMIN_BASIC_INFORMATION_OPTIONS = 'BasicInformationOptions',
  LOGIN = 'Login',
}
