import Person from '@/model/person';
import PersonRole from '@/model/person-role';
import Protector from '@/model/protector';
import Qualification from '@/model/qualification';
import Tool from '@/model/tool';
import { ToolCategory } from '@/model/tool-category';
import WorkField from '@/model/work-field';
import store from '@/store';

export const optionsMixin = {
  methods: {
    loadRoles() {
      store.dispatch('personRoleStorage/getRoles');
    },
    loadProtectors() {
      store.dispatch('protectorStorage/getProtectors');
    },
    loadWorkFields() {
      store.dispatch('workFieldStorage/getWorkFields');
    },
    loadQualifications() {
      store.dispatch('qualificationStorage/getQualifications');
    },
    loadCategories() {
      store.dispatch('toolCategoryStorage/getToolCategories');
    },
    loadBusionessTypes() {
      store.dispatch('businessStorage/getTypes');
    },
    loadPersonCategories() {
      store.dispatch('personCategoryStorage/getPersonCategories');
    },
    roleOptions(roles: PersonRole[], person?: Person): PersonRole[] {
      const options = roles;
      if (person) {
        person.roles.forEach((role: PersonRole) => {
          if (!options.find((item: PersonRole) => item.id === role.id)) {
            options.unshift(role);
          }
        });
      }
      return options;
    },
    protectorOptions(protectors: Protector[], person?: Person): Protector[] {
      const options = protectors;
      if (person) {
        person.individualProtectors.forEach((protector: Protector) => {
          if (!options.find((item: Protector) => item.id === protector.id)) {
            options.unshift(protector);
          }
        });
      }
      return options;
    },
    qualificationOptions(qualifications: Qualification[], person?: Person): Qualification[] {
      const options = qualifications;
      if (person) {
        person.qualifications.forEach((qualification: Qualification) => {
          if (!options.find((item: Qualification) => item.id === qualification.id)) {
            options.unshift(qualification);
          }
        });
      }
      return options;
    },
    workFieldOptions(workFields: WorkField[], person?: Person): WorkField[] {
      const options = workFields;
      if (person) {
        person.workFields.forEach((workField: WorkField) => {
          if (!options.find((item: WorkField) => item.id === workField.id)) {
            options.unshift(workField);
          }
        });
      }
      return options;
    },
    categoryOptions(categories: ToolCategory[], tool?: Tool): ToolCategory[] {
      const options = categories;
      if (tool && tool.category && !options.find((item: ToolCategory) => item.id === tool.category?.id)) {
        options.unshift(tool.category);
      }
      return options;
    },
  },
};
