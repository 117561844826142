import Business from '@/model/business';
import BusinessType from '@/model/business-type';
import Person from '@/model/person';
import Protector from '@/model/protector';
import Qualification from '@/model/qualification';
import Tool from '@/model/tool';
import User from '@/model/user';
import WorkField from '@/model/work-field';

export const filterMixin = {
  methods: {
    personNameFilter(queryText: string, item: Person | User): boolean {
      return `${item.lastName} ${item.firstName}`.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
    itemNameFilter(
      queryText: string,
      item: Business | Tool | WorkField | Qualification | Protector | BusinessType,
    ): boolean {
      return item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1;
    },
  },
};
