<template>
  <router-view />
</template>

<script lang="ts">

export default {
  name: 'App',
};
</script>
