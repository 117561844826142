import { Role } from '@/model/enums/role';
import Requirement from '@/model/requirement';
import User from '@/model/user';
import Document from '@/model/document';
import store from '@/store';
import { formatterMixin } from '@/mixins/formatters';

export const permissionMixin = {
  computed: {
    canSeeDashboard(): boolean {
      return (
        store.getters['currentUserStorage/isLoggedIn'] &&
        store.getters['currentUserStorage/getRoles'].some((role: Role) =>
          [Role.MANAGER, Role.EMPLOYEE, Role.ADMIN].includes(role),
        )
      );
    },
    canSeeDocuments(): boolean {
      return (
        store.getters['currentUserStorage/isLoggedIn'] &&
        store.getters['currentUserStorage/getRoles'].some((role: Role) =>
          [Role.MANAGER, Role.EMPLOYEE, Role.ADMIN, Role.GUEST].includes(role),
        )
      );
    },
    canConfirmRequirement(): boolean {
      return (
        store.getters['currentUserStorage/isLoggedIn'] &&
        store.getters['currentUserStorage/getRoles'].some((role: Role) => [Role.ADMIN, Role.MANAGER].includes(role))
      );
    },
    canDoBasicInformationActions(): boolean {
      return (
        store.getters['currentUserStorage/isLoggedIn'] &&
        store.getters['currentUserStorage/getRoles'].some((role: Role) => [Role.EMPLOYEE, Role.ADMIN].includes(role))
      );
    },
    canEditDocumentContainer(): boolean {
      return (
        store.getters['currentUserStorage/isLoggedIn'] &&
        store.getters['currentUserStorage/getRoles'].some((role: Role) => [Role.EMPLOYEE, Role.ADMIN].includes(role))
      );
    },
    canAddRequirement(): boolean {
      return (
        store.getters['currentUserStorage/isLoggedIn'] &&
        store.getters['currentUserStorage/getRoles'].some((role: Role) => [Role.EMPLOYEE, Role.ADMIN].includes(role))
      );
    },
    canSeeMessages(): boolean {
      return (
        store.getters['currentUserStorage/isLoggedIn'] &&
        store.getters['currentUserStorage/getRoles'].some((role: Role) =>
          [Role.EMPLOYEE, Role.ADMIN, Role.MANAGER, Role.HR, Role.PROCUREMENT].includes(role),
        )
      );
    },
    canAddMessages(): boolean {
      return (
        store.getters['currentUserStorage/isLoggedIn'] &&
        store.getters['currentUserStorage/getRoles'].some((role: Role) =>
          [Role.ADMIN, Role.HR, Role.PROCUREMENT].includes(role),
        )
      );
    },
    canConfirmMessages(): boolean {
      return (
        store.getters['currentUserStorage/isLoggedIn'] &&
        store.getters['currentUserStorage/getRoles'].some((role: Role) =>
          [Role.ADMIN, Role.EMPLOYEE].includes(role),
        )
      );
    },
    canAddDeliverers(): boolean {
      return (
        store.getters['currentUserStorage/isLoggedIn'] &&
        store.getters['currentUserStorage/getRoles'].some((role: Role) =>
          [Role.ADMIN, Role.HR, Role.PROCUREMENT, Role.EMPLOYEE].includes(role),
        )
      );
    },
  },
  methods: {
    isProcessOwner(requirement: Requirement): boolean {
      return requirement?.processOwnerEmails?.includes(store.getters['currentUserStorage/getCurrentUser'].email);
    },
    canModifyProcessOwners(requirement: Requirement): boolean {
      return this.isProcessOwner(requirement) || store.getters['currentUserStorage/isAdmin'];
    },
    canDeleteProcessOwner(user: User, requirement: Requirement): boolean {
      return (
        this.canModifyProcessOwners(requirement) && user.id !== store.getters['currentUserStorage/getCurrentUser'].id
      );
    },
    canEditRequirement(requirement: Requirement): boolean {
      return this.isProcessOwner(requirement) || store.getters['currentUserStorage/isAdmin'];
    },
    canDeleteDocument(requirement: Requirement, document: Document): boolean {
      return (
        this.canEditRequirement(requirement) ||
        formatterMixin.methods.fullName(store.getters['currentUserStorage/getCurrentUser']) === document.author
      );
    },
    isEditableRequirementField(requirement: Requirement): boolean {
      return (
        (!requirement.type && (!requirement.subrequirements || requirement.subrequirements?.length === 0)) ||
        !!requirement.type
      );
    },
  },
};
