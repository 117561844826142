import { Role } from '@/model/enums/role';
import { Category } from '@/model/enums/category';
import File from '@/model/file';
import Business from '@/model/business';

export default class UserDetails {
  public id: number;
  public email: string;
  public firstName: string;
  public lastName: string;
  public roles: Role[];
  public external: boolean;
  public transporterBusiness: string;
  public workfields: Category[];
  public profilePicture: File;
  public lastLogin: Date;
  public business: Business;
  public deleted: boolean;
  public firstlogin: boolean;
  public guestExpiryDate: string;

  constructor(
    id: number,
    email: string,
    firstName: string,
    lastName: string,
    roles: Role[],
    external: boolean,
    transporterBusiness: string,
    workfields: Category[],
    profilePicture: File,
    lastLogin: Date,
    business: Business,
    deleted: boolean,
    firstlogin: boolean,
    guestExpiryDate: string,
  ) {
    this.id = id;
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.roles = roles;
    this.external = external;
    this.transporterBusiness = transporterBusiness;
    this.workfields = workfields;
    this.profilePicture = profilePicture;
    this.lastLogin = lastLogin;
    this.business = business;
    this.deleted = deleted;
    this.firstlogin = firstlogin;
    this.guestExpiryDate = guestExpiryDate;
  }
}
