import axios from 'axios';
import Requirement from '@/model/requirement';
import Task from '@/model/task';
import TaskCompletitionRequest from '@/model/request/task-completition-request';
import TaskDeadlineRequest from '@/model/request/task-deadline-request';
import TaskAssigneeRequest from '@/model/request/task-assignee-request';
import PageOptions from '@/model/page/page-options';
import Document from '@/model/document';

export default {
  namespaced: true,
  state: {
    tasks: new Array<Task>(),
  },
  mutations: {
    setTasks(state: { tasks: Task[] }, payload: Task[]) {
      state.tasks = payload;
    },
    setFinishedTasks(state: { tasks: Task[] }, payload: Task) {
      const nextTask = state.tasks.find((next: Task) => next.taskOrder === payload.taskOrder + 1);
      if (nextTask) {
        nextTask.previousFinished = !payload.finished;
      }
      state.tasks.forEach((t: Task) => {
        if (t.id === payload.id) {
          t.finished = !payload.finished;
        } else if (!payload.finished && t.taskOrder > payload.taskOrder) {
          t.previousFinished = false;
          t.finished = false;
        }
      });
    },
    setTask(state: { tasks: Task[] }, payload: Task) {
      const task = state.tasks.find((item: Task) => item.id === payload.id);
      if (task) {
        task.endDate = payload.endDate;
        task.assignee = payload.assignee;
      }
    },
    addDocuments(state: { tasks: Task[] }, { taskId, documents }: { taskId: number; documents: Document[] }) {
      const task = state.tasks.find((item: Task) => item.id === taskId);
      if (task) {
        task.documents = task?.documents.concat(documents);
      }
    },
    modifyDocument(state: { tasks: Task[] }, { document, taskId }: { document: Document; taskId: number }) {
      const task = state.tasks.find((item: Task) => item.id === taskId);
      if (task) {
        const documentIndex = task.documents.findIndex((doc: Document) => doc.id === document.id);
        if (documentIndex >= 0) {
          Object.assign(task.documents[documentIndex], document);
        }
      }
    },
    removeDocument(state: { tasks: Task[] }, { documentId, taskId }: { documentId: number; taskId: number }) {
      const task = state.tasks.find((item: Task) => item.id === taskId);
      if (task) {
        task.documents = task.documents.filter((document: Document) => document.id !== documentId);
      }
    },
    setExpiryDate(state: { tasks: Task[] }, payload: Document) {
      const task = state.tasks.find((item: Task) => item.id === payload.taskId);
      if (task) {
        const document = task?.documents.find((item: Document) => item.id === payload.id);
        if (document) {
          Object.assign(document, payload);
        }
      }
    },
  },
  actions: {
    getAllTasks({ commit, dispatch }: any, requirementId: number) {
      return axios
        .get(`/requirements/${requirementId}/tasks`)
        .then(({ data }) => {
          commit('setTasks', data);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    manageTaskCompletition(
      { dispatch, commit }: any,
      { request, requirement, task }: { request: TaskCompletitionRequest; requirement: Requirement; task: Task },
    ) {
      return axios
        .post(`/requirements/${requirement.id}/tasks/${task.id}/finish`, request)
        .then(({ data }) => {
          if (data) {
            commit('setFinishedTasks', task);
            commit(
              'requirementStorage/setFinishedTasks',
              {
                requirement,
                task,
                finished: request.finished,
              },
              { root: true },
            );
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
          if (error === 'notification.error.requirement.already-confirmed') {
            commit('setConfirmed', requirement);
          }
        });
    },
    setTaskDeadline(
      { dispatch, commit }: any,
      {
        taskDeadlineRequest,
        taskId,
        requirement,
      }: { taskDeadlineRequest: TaskDeadlineRequest; taskId: number; requirement: Requirement },
    ) {
      return axios
        .post(`/requirements/${requirement.id}/tasks/${taskId}/deadline`, taskDeadlineRequest)
        .then(({ data }) => {
          if (data) {
            commit('setTask', data);
            commit('requirementStorage/setTask', { task: data, requirement }, { root: true });
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    setTaskAssignee(
      { dispatch, commit }: any,
      {
        taskAssigneeRequest,
        taskId,
        requirement,
      }: { taskAssigneeRequest: TaskAssigneeRequest; taskId: number; requirement: Requirement },
    ) {
      return axios
        .post(`/requirements/${requirement.id}/tasks/${taskId}/assignee`, taskAssigneeRequest)
        .then(({ data }) => {
          if (data) {
            commit('setTask', data);
            commit('requirementStorage/setTask', { task: data, requirement }, { root: true });
            return data.assignee;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getTasksWithPaging({ commit, dispatch }: any, { page, requirement }: { page: number; requirement: Requirement }) {
      const pageOptions = new PageOptions(page, 5);
      return axios
        .get(`/requirements/${requirement.id}/tasks/paging`, { params: pageOptions })
        .then(({ data }) => {
          commit('requirementStorage/setLoadedTasks', { pageableTask: data, requirement }, { root: true });
          return data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getTasks(state: { tasks: Task[] }) {
      return state.tasks;
    },
  },
};
