import { Role } from '@/model/enums/role';
import { Category } from '@/model/enums/category';
import File from '@/model/file';
import UserDetails from '@/model/user-details';
import Business from '@/model/business';

export default class User extends UserDetails {
  public accessToken: string;
  public accessTokenExpiry: number;
  public refreshToken: string;
  public refreshExpiry: number;
  public allowEmails: boolean;

  constructor(
    id: number,
    email: string,
    firstName: string,
    lastName: string,
    roles: Role[],
    external: boolean,
    transporterBusiness: string,
    workfields: Category[],
    profilePicture: File,
    lastLogin: Date,
    business: Business,
    deleted: boolean,
    firstlogin: boolean,
    guestExpiryDate: string,
    accessToken: string,
    accessTokenExpiry: number,
    refreshToken: string,
    refreshExpiry: number,
    allowEmails: boolean,
  ) {
    super(
      id,
      email,
      firstName,
      lastName,
      roles,
      external,
      transporterBusiness,
      workfields,
      profilePicture,
      lastLogin,
      business,
      deleted,
      firstlogin,
      guestExpiryDate,
    );
    this.accessToken = accessToken;
    this.accessTokenExpiry = accessTokenExpiry;
    this.refreshToken = refreshToken;
    this.refreshExpiry = refreshExpiry;
    this.allowEmails = allowEmails;
  }
}
