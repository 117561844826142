import { State } from '@/model/enums/state';
import PersonFilterOptions from '@/model/filter/person-filter-options';

export default class PersonFilterRequest {
  public text: string;
  public state: State | undefined;
  public roles: string | undefined;
  public workfields: string | undefined;
  public qualifications: string | undefined;
  public individualProtectors: string | undefined;
  public condition: boolean | undefined;
  public educationText: string;
  public medicalExaminationText: string;
  public operationalUnitId?: number;

  constructor(text: string, filterOptions: PersonFilterOptions) {
    this.text = text;
    this.state = filterOptions.state ?? undefined;
    this.roles = filterOptions.roles && filterOptions.roles.length > 0 ? filterOptions.roles.join(',') : undefined;
    this.workfields =
      filterOptions.workfields && filterOptions.workfields.length > 0 ? filterOptions.workfields.join(',') : undefined;
    this.qualifications =
      filterOptions.qualifications && filterOptions.qualifications.length > 0
        ? filterOptions.qualifications.join(',')
        : undefined;
    this.individualProtectors =
      filterOptions.individualProtectors && filterOptions.individualProtectors.length > 0
        ? filterOptions.individualProtectors.join(',')
        : undefined;
    this.condition = filterOptions.condition;
    this.educationText = filterOptions.educationText;
    this.medicalExaminationText = filterOptions.medicalExaminationText;
  }

  public requestParamsToArray(): {
    text: string;
    state: State | undefined;
    roles: string[] | undefined;
    workfields: string[] | undefined;
    qualifications: string[] | undefined;
    individualProtectors: string[] | undefined;
    condition: boolean | undefined;
    educationText: string;
    medicalExaminationText: string;
    operationalUnitId?: number;
  } {
    return {
      ...this,
      roles: this.roles ? this.roles.split(',') : undefined,
      workfields: this.workfields ? this.workfields.split(',') : undefined,
      qualifications: this.qualifications ? this.qualifications.split(',') : undefined,
      individualProtectors: this.individualProtectors ? this.individualProtectors.split(',') : undefined,
    };
  }
}
