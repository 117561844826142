import axios from 'axios';
import { Category } from '@/model/enums/category';
import EmployeeList from '@/model/employee-list';
import UserDetails from '@/model/user-details';
import PageOptions from '@/model/page/page-options';
import Pageable from '@/model/page/pageable';
import User from '@/model/user';
import File from '@/model/file';
import ChangeNameRequest from '@/model/request/change-name-request';

export default {
  namespaced: true,
  state: {
    assignableEmployees: Array<EmployeeList>(),
    users: {} as Pageable<UserDetails>,
  },
  mutations: {
    setUsers(state: { users: Pageable<UserDetails> }, payload: Pageable<UserDetails>) {
      if (payload.currentPage === 0) {
        state.users = payload;
      } else {
        state.users.items = state.users.items.concat(payload.items);
        state.users.currentPage = payload.currentPage;
      }
    },
    setAssignableEmployees(state: { assignableEmployees: EmployeeList[] }, payload: EmployeeList) {
      const index = state.assignableEmployees.findIndex((item: EmployeeList) => item.category === payload.category);

      if (index > -1) {
        state.assignableEmployees[index] = payload;
      } else {
        state.assignableEmployees.push(payload);
      }
    },
    setEmail(state: { users: Pageable<UserDetails> }, { userId, newEmail }: { userId: number; newEmail: string }) {
      const pageableUsers = new Pageable<UserDetails>(state.users);
      pageableUsers.getByIdIfExists(userId, (item) => (item.email = newEmail));
    },
    setName(
      state: { users: Pageable<UserDetails> },
      { userId, newName }: { userId: number; newName: ChangeNameRequest },
    ) {
      const pageableUsers = new Pageable<UserDetails>(state.users);
      pageableUsers.getByIdIfExists(userId, (item) => {
        item.firstName = newName.firstName;
        item.lastName = newName.lastName;
      });
    },
    setProfilePicture(
      state: { assignableEmployees: EmployeeList[]; users: Pageable<UserDetails> },
      { userId, picture }: { userId: number; picture: File },
    ) {
      state.assignableEmployees.forEach((item: EmployeeList) => {
        const index = item.employees.findIndex((employee: User) => employee.id === userId);
        if (index > -1) {
          item.employees[index].profilePicture = picture;
        }
      });
      const pageableUsers = new Pageable<UserDetails>(state.users);
      pageableUsers.getByIdIfExists(userId, (item) => {
        item.profilePicture = picture;
      });
    },
  },
  actions: {
    getUsersPage({ commit, dispatch }: any, pageOptions: PageOptions) {
      return axios
        .get('/user', { params: pageOptions })
        .then(({ data }) => {
          if (data) {
            commit('setUsers', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getEmployees({ commit, dispatch }: any, category: Category) {
      return axios
        .get('/user/employee', {
          params: {
            category,
          },
        })
        .then(({ data }) => {
          commit('setAssignableEmployees', { category, employees: data });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getUsers(state: { users: Pageable<UserDetails> }) {
      return state.users;
    },
    getAssignableEmployees(state: { assignableEmployees: EmployeeList[] }) {
      return state.assignableEmployees;
    },
  },
};
