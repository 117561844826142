import axios from 'axios';
import { Commit, Dispatch } from 'vuex';
import Education from '@/model/education';
import Pageable from '@/model/page/pageable';
import PageOptions from '@/model/page/page-options';
import OperationalUnitFilterRequest from '@/model/request/operational-unit-filter-request';
import Person from '@/model/person';

export default {
  namespaced: true,
  state: {
    personEducations: {} as Pageable<Education>,
  },
  mutations: {
    setPersonEducations(state: { personEducations: Pageable<Education> }, payload: Pageable<Education>) {
      if (payload.currentPage === 0) {
        state.personEducations = payload;
      } else {
        state.personEducations.items = state.personEducations.items.concat(payload.items);
        state.personEducations.currentPage = payload.currentPage;
      }
    },
    addPersonEducation(state: { personEducations: Pageable<Education> }, payload: Education) {
      state.personEducations.items = state.personEducations.items
        ? state.personEducations?.items.concat(payload)
        : [payload];
    },
    modifyEducation(state: { personEducations: Pageable<Education> }, payload: Education) {
      const pageableEducations = new Pageable<Education>(state.personEducations);
      pageableEducations.setById(payload.id, payload);
    },
    removePersonEducation(state: { personEducations: Pageable<Education> }, payload: number) {
      const pageableEducations = new Pageable<Education>(state.personEducations);
      pageableEducations.removeById(payload);
    },
  },
  actions: {
    getPersonEducations(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      {
        id,
        pageOptions,
        filterOptions,
      }: { id: number; pageOptions: PageOptions; filterOptions: OperationalUnitFilterRequest },
    ) {
      return axios
        .get(`/people/${id}/educations`, { params: { ...pageOptions, ...filterOptions } })
        .then(({ data }) => {
          commit('setPersonEducations', data);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    addPersonEducation(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { person, formData }: { person: Person; formData: FormData },
    ) {
      return axios
        .post(`/people/${person.id}/education`, formData)
        .then(({ data }) => {
          if (data) {
            commit('addPersonEducation', data);
            dispatch('personStorage/getState', person, { root: true });
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modify(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { person, id, formData }: { person: Person; id: number; formData: FormData },
    ) {
      return axios
        .put(`/educations/${id}`, formData)
        .then(({ data }) => {
          if (data) {
            commit('modifyEducation', data);
            dispatch('personStorage/getState', person, { root: true });
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    deletePersonEducation(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { person, educationId }: { person: Person; educationId: number },
    ) {
      return axios
        .delete(`/educations/${educationId}`)
        .then(() => {
          commit('removePersonEducation', educationId);
          dispatch('personStorage/getState', person, { root: true });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getPersonEducations(state: { personEducations: Pageable<Education> }) {
      return state.personEducations;
    },
  },
};
