import { i18n } from '@/i18n';
import { Composer } from 'vue-i18n';
import Pageable from '@/model/page/pageable';
import BasicInformationRequest from '@/model/request/basic-information-request';
import Notification from '@/model/notification';
import axios from 'axios';
import { Commit, Dispatch } from 'vuex';
import PersonRole from '@/model/person-role';
import PageOptions from '@/model/page/page-options';
import AdminBasicInformationOptionsFilterRequest from '@/model/request/admin-basic-information-options-filter-request';

const { t }: Composer = i18n.global;

export default {
  namespaced: true,
  state: {
    roles: new Array<PersonRole>(),
    rolePageable: {} as Pageable<PersonRole>,
  },
  mutations: {
    setRoles(state: { roles: PersonRole[] }, payload: PersonRole[]) {
      state.roles = payload;
    },
    setRolePageable(state: { rolePageable: Pageable<PersonRole> }, payload: Pageable<PersonRole>) {
      state.rolePageable = payload;
    },
    setModifiedRole(state: { rolePageable: Pageable<PersonRole> }, payload: PersonRole) {
      const index = state.rolePageable.items.findIndex((role: PersonRole) => role.id === payload.id);
      if (index >= 0) {
        Object.assign(state.rolePageable.items[index], payload);
      } else {
        state.rolePageable.items.push(payload);
      }
    },
    removeRole(state: { rolePageable: Pageable<PersonRole> }, payload: number) {
      const pageableRoles = new Pageable<PersonRole>(state.rolePageable);
      pageableRoles.removeById(payload);
    },
  },
  actions: {
    getRoles({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
      return axios
        .get('/person-roles')
        .then(({ data }) => {
          if (data) {
            commit('setRoles', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getPageableRoles(
      { commit, dispatch, rootGetters }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
      pageOptions: PageOptions,
    ) {
      const searchText = rootGetters['filterStorage/getAdminBasicInformationOptionsSearchText'];
      const request = new AdminBasicInformationOptionsFilterRequest(searchText);
      return axios
        .get('/person-roles/pageable', { params: { ...pageOptions, ...request } })
        .then(({ data }) => {
          if (data) {
            commit('setRolePageable', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    add({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: BasicInformationRequest) {
      return axios
        .post('/person-roles', request)
        .then(({ data }) => {
          if (data) {
            commit('setModifiedRole', data);
            dispatch(
              'showSuccessNotification',
              { message: t('admin.basic-information-settings.role.add.success', { name: data.name }) },
              { root: true },
            );
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modify({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: BasicInformationRequest) {
      return axios
        .put('/person-roles', request)
        .then(({ data }) => {
          if (data) {
            commit('setModifiedRole', data);
            dispatch(
              'showSuccessNotification',
              {
                message: t('admin.basic-information-settings.role.modify.success', { name: data.name }),
              },
              { root: true },
            );
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, id: number) {
      return axios
        .delete(`/person-roles/${id}`)
        .then(() => {
          commit('removeRole', id);
          dispatch(
            'showSuccessNotification',
            new Notification(t('admin.basic-information-settings.role.delete.success').toString()),
            { root: true },
          );
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getRoles(state: { roles: PersonRole[] }) {
      return state.roles;
    },
    getPageableRoles(state: { rolePageable: Pageable<PersonRole> }) {
      return state.rolePageable;
    },
  },
};
