import { Category } from '@/model/enums/category';
import Pageable from '@/model/page/pageable';
import { Model } from '@/model/model';

export default class PageableRequirement<T extends Model> extends Pageable<T> {
  public category: Category;
  public totalRequirements: number;

  constructor(
    pageable: Pageable<T>,
    category: Category,
    totalRequirements: number,
  ) {
    super(pageable);
    this.category = category;
    this.totalRequirements = totalRequirements;
  }
}
