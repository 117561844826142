import BusinessType from '@/model/business-type';
import { PlaceTrait } from '@/model/enums/place-trait';
import PlaceFilterOptions from '@/model/filter/place-filter-options';
import { Module } from 'vuex';

export const placesFilterStorage: Module<PlaceFilterOptions, unknown> = {
  namespaced: true,
  state: {
    types: [],
    active: undefined,
    dangerousActivities: undefined,
    placeTrait: undefined,
  },
  mutations: {
    toggleType(state, value: BusinessType) {
      const index = state.types.findIndex((e) => e === value.id);

      if (index >= 0) {
        state.types.splice(index, 1);
      } else {
        state.types.push(value.id);
      }
    },
    setActive(state, newValue: boolean) {
      state.active = newValue;
    },
    setDangerousActivities(state, newValue: boolean) {
      state.dangerousActivities = newValue;
    },
    setPlaceTrait(state, newValue: PlaceTrait) {
      state.placeTrait = newValue;
    },
    clearPlaceFilterOptions(state) {
      state.types.splice(0);
      state.active = undefined;
      state.dangerousActivities = undefined;
      state.placeTrait = undefined;
    },
  },
  getters: {
    getPlaceFilterOptions(state): PlaceFilterOptions {
      return state;
    },
    isFiltering({ types, active, dangerousActivities, placeTrait }): boolean {
      return types.length > 0 || active !== undefined || dangerousActivities !== undefined || placeTrait !== undefined;
    },
  },
};
