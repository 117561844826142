import { ConfirmationType } from '@/model/enums/confirmation-type';
import { Prescriber } from '@/model/enums/prescriber';
import { State } from '@/model/enums/state';
import { DateRange } from '@/model/enums/date-range';
import RequirementType from '@/model/requirement-type';

export default class RequirementFilterOptions {
  public prescribers: Prescriber[];
  public type: RequirementType | undefined;
  public confirmationType: ConfirmationType | undefined;
  public state: State | undefined;
  public startDate: string;
  public endDate: string;
  public dateRange: DateRange | undefined;
  public text: string;

  constructor() {
    this.prescribers = [];
    this.type = undefined;
    this.confirmationType = undefined;
    this.state = undefined;
    this.startDate = '';
    this.endDate = '';
    this.dateRange = undefined;
    this.text = '';
  }
}
