import axios from 'axios';
import PageOptions from '@/model/page/page-options';
import AdminRequirement from '@/model/admin-requirement';
import PageableRequirement from '@/model/page/pageable-requirement';
import { adminRequirementReset } from '@/helper/methods';
import { Dispatch, Commit } from 'vuex';

export default {
  namespaced: true,
  state: {
    inactiveRequirements: {} as PageableRequirement<AdminRequirement>,
    deletedRequirements: {} as PageableRequirement<AdminRequirement>,
  },
  mutations: {
    setInactiveRequirements(
      state: { inactiveRequirements: PageableRequirement<AdminRequirement> },
      payload: PageableRequirement<AdminRequirement>,
    ) {
      if (payload.currentPage === 0) {
        state.inactiveRequirements = payload;
      } else {
        state.inactiveRequirements.items = state.inactiveRequirements.items.concat(payload.items);
        state.inactiveRequirements.currentPage = payload.currentPage;
      }
    },
    setDeletedRequirements(
      state: { deletedRequirements: PageableRequirement<AdminRequirement> },
      payload: PageableRequirement<AdminRequirement>,
    ) {
      if (payload.currentPage === 0) {
        state.deletedRequirements = payload;
      } else {
        state.deletedRequirements.items = state.deletedRequirements.items.concat(payload.items);
        state.deletedRequirements.currentPage = payload.currentPage;
      }
    },
    setRestoredRequirements(
      state: {
        inactiveRequirements: PageableRequirement<AdminRequirement>;
        deletedRequirements: PageableRequirement<AdminRequirement>;
      },
      { requirementId, isDeleted }: { requirementId: number; isDeleted: boolean },
    ) {
      if (isDeleted) {
        state.deletedRequirements = adminRequirementReset(state.deletedRequirements, requirementId);
      } else {
        state.inactiveRequirements = adminRequirementReset(state.inactiveRequirements, requirementId);
      }
    },
  },
  actions: {
    getInactivePageable(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { pageOptions, text }: { pageOptions: PageOptions; text: string },
    ) {
      return axios
        .get('/requirement/inactive', { params: { ...pageOptions, text } })
        .then(({ data }) => {
          if (data) {
            commit('setInactiveRequirements', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getDeletedPageable(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { pageOptions, text }: { pageOptions: PageOptions; text: string },
    ) {
      return axios
        .get('/requirement/deleted', { params: { ...pageOptions, text } })
        .then(({ data }) => {
          if (data) {
            commit('setDeletedRequirements', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    restore(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { requirementId, isDeleted }: { requirementId: number; isDeleted: boolean },
    ) {
      return axios
        .post(`/requirement/${requirementId}/restore${isDeleted ? '-deleted' : '-inactive'}`)
        .then(() => {
          commit('setRestoredRequirements', { requirementId, isDeleted });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getInactiveRequirements(state: { inactiveRequirements: PageableRequirement<AdminRequirement> }) {
      return state.inactiveRequirements;
    },
    getDeletedRequirements(state: { deletedRequirements: PageableRequirement<AdminRequirement> }) {
      return state.deletedRequirements;
    },
  },
};
