import axios from 'axios';
import { Commit, Dispatch } from 'vuex';
import Pageable from '@/model/page/pageable';
import PageOptions from '@/model/page/page-options';
import OperationalUnitFilterRequest from '@/model/request/operational-unit-filter-request';
import MedicalExamination from '@/model/medical-examination';
import Person from '@/model/person';

export default {
  namespaced: true,
  state: {
    personMedicalExaminations: {} as Pageable<MedicalExamination>,
  },
  mutations: {
    setPersonMedicalExaminations(
      state: { personMedicalExaminations: Pageable<MedicalExamination> },
      payload: Pageable<MedicalExamination>,
    ) {
      if (payload.currentPage === 0) {
        state.personMedicalExaminations = payload;
      } else {
        state.personMedicalExaminations.items = state.personMedicalExaminations.items.concat(payload.items);
        state.personMedicalExaminations.currentPage = payload.currentPage;
      }
    },
    addPersonMedicalExamination(
      state: { personMedicalExaminations: Pageable<MedicalExamination> },
      payload: MedicalExamination,
    ) {
      state.personMedicalExaminations.items = state.personMedicalExaminations.items
        ? state.personMedicalExaminations?.items.concat(payload)
        : [payload];
    },
    modifyMedicalExamination(
      state: { personMedicalExaminations: Pageable<MedicalExamination> },
      payload: MedicalExamination,
    ) {
      const pageableMedicalExaminations = new Pageable<MedicalExamination>(state.personMedicalExaminations);
      pageableMedicalExaminations.setById(payload.id, payload);
    },
    removePersonMedicalExamination(
      state: { personMedicalExaminations: Pageable<MedicalExamination> },
      payload: number,
    ) {
      const pageableMedicalExaminations = new Pageable<MedicalExamination>(state.personMedicalExaminations);
      pageableMedicalExaminations.removeById(payload);
    },
  },
  actions: {
    getPersonMedicalExaminations(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      {
        id,
        pageOptions,
        filterOptions,
      }: { id: number; pageOptions: PageOptions; filterOptions: OperationalUnitFilterRequest },
    ) {
      return axios
        .get(`/people/${id}/medical-examinations`, { params: { ...pageOptions, ...filterOptions } })
        .then(({ data }) => {
          commit('setPersonMedicalExaminations', data);
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    addPersonMedicalExamination(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { person, formData }: { person: Person; formData: FormData },
    ) {
      return axios
        .post(`/people/${person.id}/medical-examinations`, formData)
        .then(({ data }) => {
          if (data) {
            commit('addPersonMedicalExamination', data);
            dispatch('personStorage/getState', person, { root: true });
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modify(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { person, id, formData }: { person: Person; id: number; formData: FormData },
    ) {
      return axios
        .put(`/medical-examinations/${id}`, formData)
        .then(({ data }) => {
          if (data) {
            commit('modifyMedicalExamination', data);
            dispatch('personStorage/getState', person, { root: true });
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    deletePersonMedicalExamination(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { person, medicalExaminationId }: { person: Person; medicalExaminationId: number },
    ) {
      return axios
        .delete(`/medical-examinations/${medicalExaminationId}`)
        .then(() => {
          commit('removePersonMedicalExamination', medicalExaminationId);
          dispatch('personStorage/getState', person, { root: true });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getPersonMedicalExaminations(state: { personMedicalExaminations: Pageable<MedicalExamination> }) {
      return state.personMedicalExaminations;
    },
  },
};
