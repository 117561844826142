import { Model } from '@/model/model';

export default class Pageable<T extends Model> {
  public currentPage: number;
  public totalPages: number;
  public totalItems: number;
  public size: number;
  public items: T[];

  constructor(pageable: Pageable<T>) {
    this.currentPage = pageable.currentPage;
    this.totalItems = pageable.totalItems;
    this.totalPages = pageable.totalPages;
    this.size = pageable.size;
    this.items = pageable.items;
  }

  public getById(id: number): T | undefined {
    return this.items.find((item) => item.id === id);
  }
  public getIndexById(id: number): number {
    return this.items.findIndex((item) => item.id === id);
  }

  public getByIdIfExists(id: number, handler: (item: T) => void) {
    const item = this.getById(id);

    if (item && handler) {
      handler(item);
    }
  }

  public setById(id: number, newItem: T): Pageable<T> {
    this.getByIdIfExists(id, (item) => {
      Object.assign(item, newItem);
    });
    return this;
  }

  public removeById(id: number) {
    const itemIndex = this.items.findIndex((arrayItem) => arrayItem.id === id);
    if (itemIndex >= 0) {
      this.items.splice(itemIndex, 1);
    }
  }

  public addItem(item: T): Pageable<T> {
    this.items = this.items?.concat(item) ?? [item];

    this.increaseItems();
    return this;
  }

  public addItemToInfiniteScroll(item: T): Pageable<T> {
    if ((this.totalPages === 1 && this.items.length <= this.totalItems) || this.items.length >= this.totalItems) {
      this.items.push(item);
    }

    this.increaseItems();
    return this;
  }

  private increaseItems() {
    this.totalItems++;
    this.totalPages = Math.ceil(this.totalItems / this.size);
  }
}
