import { DangerRate } from '@/model/enums/danger-rate';
import { Module } from 'vuex';

export interface ToolFilterOptions {
  dangerRates: DangerRate[];
  active: boolean | undefined;
}

export const toolFilterStorage: Module<ToolFilterOptions, unknown> = {
  namespaced: true,
  state: {
    dangerRates: [],
    active: undefined,
  },
  mutations: {
    setActive(state, payload: ToolFilterOptions['active']) {
      state.active = payload;
    },
    toggleDangerRate(state, dangerRate: DangerRate) {
      const index = state.dangerRates.findIndex((e) => e === dangerRate);

      if (index >= 0) {
        state.dangerRates.splice(index, 1);
      } else {
        state.dangerRates.push(dangerRate);
      }
    },
    setToolFilterOptions(state, payload: ToolFilterOptions) {
      state.dangerRates.splice(0);
      state.dangerRates.push(...payload.dangerRates);
      state.active = payload.active;
    },
    clearToolFilterOptions(state) {
      state.dangerRates.splice(0);
      state.active = undefined;
    },
  },
  getters: {
    getToolFilterOptions(state): ToolFilterOptions {
      return state;
    },
    isFiltering(state) {
      return state.dangerRates.length > 0 || state.active !== undefined;
    },
  },
};
