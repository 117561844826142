export enum TypeIcon {
  REGULATION = 'regulation.svg',
  EDUCATION = 'education.svg',
  MEASUREMENT = 'measurement.svg',
  REVIEW = 'review.svg',
  AUDIT = 'audit.svg',
  EMERGENCY = 'emergency.svg',
  CUSTOM_1 = 'type01.svg',
  CUSTOM_2 = 'type02.svg',
  CUSTOM_3 = 'type03.svg',
  CUSTOM_4 = 'type04.svg',
  CUSTOM_5 = 'type05.svg',
  CUSTOM_6 = 'type06.svg',
  OTHER = 'type07.svg',
}
