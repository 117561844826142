import { i18n } from '@/i18n';
import { Composer } from 'vue-i18n';
import Pageable from '@/model/page/pageable';
import BasicInformationRequest from '@/model/request/basic-information-request';
import Notification from '@/model/notification';
import axios from 'axios';
import { Commit, Dispatch } from 'vuex';
import BusinessType from '@/model/business-type';
import PageOptions from '@/model/page/page-options';
import AdminBasicInformationOptionsFilterRequest from '@/model/request/admin-basic-information-options-filter-request';

const { t }: Composer = i18n.global;

export default {
  namespaced: true,
  state: {
    businessTypes: new Array<BusinessType>(),
    businessTypePageable: {} as Pageable<BusinessType>,
  },
  mutations: {
    setBuinessTypes(state: { businessTypes: BusinessType[] }, payload: BusinessType[]) {
      state.businessTypes = payload;
    },
    setBusinessTypePageable(state: { businessTypePageable: Pageable<BusinessType> }, payload: Pageable<BusinessType>) {
      state.businessTypePageable = payload;
    },
    setModifiedBusinessType(state: { businessTypePageable: Pageable<BusinessType> }, payload: BusinessType) {
      const index = state.businessTypePageable.items.findIndex((type: BusinessType) => type.id === payload.id);
      if (index >= 0) {
        Object.assign(state.businessTypePageable.items[index], payload);
      } else {
        state.businessTypePageable.items.push(payload);
      }
    },
    removeBusinessType(state: { businessTypePageable: Pageable<BusinessType> }, payload: number) {
      const pageableTypes = new Pageable<BusinessType>(state.businessTypePageable);
      pageableTypes.removeById(payload);
    },
  },
  actions: {
    getBusinessTypes({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
      return axios
        .get('/business-types')
        .then(({ data }) => {
          if (data) {
            commit('setBuinessTypes', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getPageableBusinessTypes(
      { commit, dispatch, rootGetters }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
      pageOptions: PageOptions,
    ) {
      const searchText = rootGetters['filterStorage/getAdminBasicInformationOptionsSearchText'];
      const request = new AdminBasicInformationOptionsFilterRequest(searchText);
      return axios
        .get('/business-types/pageable', { params: { ...pageOptions, ...request } })
        .then(({ data }) => {
          if (data) {
            commit('setBusinessTypePageable', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    add({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: BasicInformationRequest) {
      return axios
        .post('/business-types', request)
        .then(({ data }) => {
          if (data) {
            commit('setModifiedBusinessType', data);
            dispatch(
              'showSuccessNotification',
              { message: t('admin.basic-information-settings.type.add.success', { name: data.name }) },
              { root: true },
            );
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modify({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: BasicInformationRequest) {
      return axios
        .put('/business-types', request)
        .then(({ data }) => {
          if (data) {
            commit('setModifiedBusinessType', data);
            dispatch(
              'showSuccessNotification',
              {
                message: t('admin.basic-information-settings.type.modify.success', { name: data.name }),
              },
              { root: true },
            );
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, id: number) {
      return axios
        .delete(`/business-types/${id}`)
        .then(() => {
          commit('removeBusinessType', id);
          dispatch(
            'showSuccessNotification',
            new Notification(t('admin.basic-information-settings.type.delete.success').toString()),
            { root: true },
          );
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getBusinessTypes(state: { businessTypes: BusinessType[] }) {
      return state.businessTypes;
    },
    getPageableBusinessTypes(state: { businessTypePageable: Pageable<BusinessType> }) {
      return state.businessTypePageable;
    },
  },
};
