import DocumentContainer from '@/model/document-container';
import DocumentStoreDocument from '@/model/document-store-document';
import DocumentFilterOptions from '@/model/filter/document-filter-options';
import PageOptions from '@/model/page/page-options';
import Pageable from '@/model/page/pageable';
import PageableRequirement from '@/model/page/pageable-requirement';
import RequirementDocument from '@/model/requirement-document';
import axios from 'axios';
import { Commit, Dispatch } from 'vuex';
import { findModifiedRequirementDocument } from '@/helper/methods';
import DocumentContainerRequest from '@/model/request/document-container-request';
import Person from '@/model/person';
import Business from '@/model/business';
import Tool from '@/model/tool';

export default {
  namespaced: true,
  state: {
    containerDocuments: {} as Pageable<DocumentStoreDocument>,
  },
  mutations: {
    setContainerDocuments(
      state: { containerDocuments: Pageable<DocumentStoreDocument> },
      payload: Pageable<DocumentStoreDocument>,
    ) {
      if (payload.currentPage === 0) {
        state.containerDocuments = payload;
      } else {
        state.containerDocuments.items = state.containerDocuments.items.concat(payload.items);
        state.containerDocuments.currentPage = payload.currentPage;
      }
    },
    setDocumentVisibility(state: { containerDocuments: Pageable<DocumentStoreDocument> }, payload: number) {
      const modifiedDocument = state.containerDocuments?.items.find(
        (document: DocumentStoreDocument) => document.id === payload,
      );
      if (modifiedDocument) {
        modifiedDocument.visible = !modifiedDocument.visible;
      }
    },
  },
  actions: {
    getContainersByYear({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, year: number) {
      return axios
        .get(`/document-store/${year}`)
        .then(({ data }) => {
          if (data) {
            commit('documentStorage/setDocumentContainersByYear', data, { root: true });
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getPageableDocuments(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { pageOptions, filterOptions }: { pageOptions: PageOptions; filterOptions: DocumentFilterOptions },
    ) {
      return axios
        .get('/document-store/pageable', {
          params: { ...pageOptions, ...filterOptions },
        })
        .then(({ data }) => {
          commit('setContainerDocuments', data);
          return data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    setVisibility({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, documentId: number) {
      return axios
        .post(`/document-store/${documentId}/visibility`)
        .then(({ data }) => {
          commit('setDocumentVisibility', documentId);
          commit('documentStorage/setContainer', data, { root: true });
          return data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    setContainerTitle(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { containerId, request }: { containerId: number; request: DocumentContainerRequest },
    ) {
      return axios
        .post(`/document-store/${containerId}`, request)
        .then(({ data }) => {
          commit('documentStorage/setContainer', data, { root: true });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    addPerson(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { container, personId }: { container: DocumentContainer; personId: number },
    ) {
      return axios
        .put(`document-store/${container.id}/person/${personId}`)
        .then(({ data }) => {
          commit('documentStorage/setContainerPeople', { container, person: data }, { root: true });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    removePerson(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { container, person }: { container: DocumentContainer; person: Person },
    ) {
      return axios
        .delete(`document-store/${container.id}/person/${person.id}`)
        .then(() => {
          commit('documentStorage/setContainerPeople', { container, person }, { root: true });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    addPlace(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { container, placeId }: { container: DocumentContainer; placeId: number },
    ) {
      return axios
        .put(`document-store/${container.id}/place/${placeId}`)
        .then(({ data }) => {
          commit('documentStorage/setContainerPlaces', { container, place: data }, { root: true });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    removePlace(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { container, place }: { container: DocumentContainer; place: Business },
    ) {
      return axios
        .delete(`document-store/${container.id}/place/${place.id}`)
        .then(() => {
          commit('documentStorage/setContainerPlaces', { container, place }, { root: true });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    addTool(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { container, toolId }: { container: DocumentContainer; toolId: number },
    ) {
      return axios
        .put(`document-store/${container.id}/tool/${toolId}`)
        .then(({ data }) => {
          commit('documentStorage/setContainerTools', { container, tool: data }, { root: true });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    removeTool(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      { container, tool }: { container: DocumentContainer; tool: Tool },
    ) {
      return axios
        .delete(`document-store/${container.id}/tool/${tool.id}`)
        .then(() => {
          commit('documentStorage/setContainerTools', { container, tool }, { root: true });
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getContainerDocuments(state: { containerDocuments: Pageable<DocumentStoreDocument> }) {
      return state.containerDocuments;
    },
  },
};
