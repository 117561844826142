import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { checkGuestExpiry } from '@/helper/methods';
import { Role } from '@/model/enums/role';
import { Route } from '@/model/enums/route';
import { authorizedRoles } from '@/constants/authorized-roles';
import { i18n } from '@/i18n';
import { Composer } from 'vue-i18n';

const { t }: Composer = i18n.global;

const basicInformationRoles = authorizedRoles.find((item) => item.pageName === Route.BASIC_INFORMATION)?.roles;
const organizationalMapRoles = authorizedRoles.find((item) => item.pageName === Route.ORGANIZATIONAL_MAP)?.roles;
const adminRoles = authorizedRoles.find((item) => item.pageName === Route.ADMIN)?.roles;

const routes: RouteRecordRaw[] = [
  {
    path: t('route-path.login').toString(),
    name: Route.LOGIN,
    meta: { onlyNotLoggedIn: true },
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '',
    components: {
      default: () => import('@/views/Main.vue'),
    },
    children: [
      {
        path: t('route-path.dashboard').toString(),
        name: Route.DASHBOARD,
        meta: { onlyLoggedIn: true, roles: authorizedRoles.find((item) => item.pageName === Route.DASHBOARD)?.roles },
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: t('route-path.documents').toString(),
        name: Route.DOCUMENTS,
        meta: { onlyLoggedIn: true, roles: authorizedRoles.find((item) => item.pageName === Route.DOCUMENTS)?.roles },
        component: () => import('@/views/Documents.vue'),
      },
      {
        path: `${t('route-path.basic-information-base').toString()}/:id`,
        name: Route.BASIC_INFORMATION,
        meta: { onlyLoggedIn: true, roles: basicInformationRoles },
        redirect: { name: Route.BASIC_INFORMATION_PLACES },
        component: () => import('@/views/BasicInformation.vue'),
        children: [
          {
            name: Route.BASIC_INFORMATION_PLACES,
            meta: { onlyLoggedIn: true, roles: basicInformationRoles},
            path: `${t('route-path.basic-information-places').toString()}`,
            component: () => import('@/views/BasicInformation/Places.vue'),
          },
          {
            name: Route.BASIC_INFORMATION_PEOPLE,
            meta: { onlyLoggedIn: true, roles: basicInformationRoles},
            path: `${t('route-path.basic-information-people').toString()}`,
            component: () => import('@/views/BasicInformation/People.vue'),
          },
          {
            name: Route.BASIC_INFORMATION_TOOLS,
            meta: { onlyLoggedIn: true, roles: basicInformationRoles},
            path: `${t('route-path.basic-information-tools').toString()}`,
            component: () => import('@/views/BasicInformation/Tools.vue'),
          },
        ],
      },
      {
        path: t('route-path.organizational-map').toString(),
        name: Route.ORGANIZATIONAL_MAP,
        meta: { onlyLoggedIn: true, roles: organizationalMapRoles },
        components: {
          default: () => import('@/views/OrganizationalMap.vue'),
        },
      },
      {
        path: t('route-path.user-details').toString(),
        name: Route.USER_DETAILS,
        meta: {
          onlyLoggedIn: true,
          roles: authorizedRoles.find((item) => item.pageName === Route.USER_DETAILS)?.roles,
        },
        component: () => import('@/views/UserDetails.vue'),
      },
      {
        path: t('route-path.admin-settings').toString(),
        name: Route.ADMIN,
        redirect: { name: Route.ADMIN_USERS },
        meta: { onlyLoggedIn: true, roles: adminRoles },
        components: {
          default: () => import('@/views/Admin.vue'),
        },
        children: [
          {
            path: `${t('route-path.admin-settings').toString()}${t('route-path.admin-users').toString()}`,
            name: Route.ADMIN_USERS,
            meta: { onlyLoggedIn: true, roles: adminRoles },
            component: () => import('@/views/admin/Users.vue'),
          },
          {
            path: `${t('route-path.admin-settings').toString()}${t('route-path.admin-types').toString()}`,
            name: Route.ADMIN_TYPES,
            meta: { onlyLoggedIn: true, roles: adminRoles },
            component: () => import('@/views/admin/Types.vue'),
          },
          {
            path: `${t('route-path.admin-settings').toString()}${t('route-path.admin-requirements').toString()}`,
            name: Route.ADMIN_REQUIREMENTS,
            meta: { onlyLoggedIn: true, roles: adminRoles },
            component: () => import('@/views/admin/Requirements.vue'),
          },
          {
            path: `${t('route-path.admin-settings').toString()}${t('route-path.admin-options').toString()}`,
            name: Route.ADMIN_BASIC_INFORMATION_OPTIONS,
            meta: { onlyLoggedIn: true, roles: adminRoles },
            component: () => import('@/views/admin/BasicInformationOptions.vue'),
          },
          {
            path: '/:catchAll(.*)',
            redirect: { name: Route.DASHBOARD },
          },
        ],
      },
      {
        path: '/:catchAll(.*)',
        redirect: { name: Route.DASHBOARD },
      },
    ],
  },
];

const history = createWebHistory(process.env.BASE_URL);

const router = createRouter({
  history: history,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (!store.getters['configurationStorage/isConfigLoaded']) {
    await store.dispatch('configurationStorage/loadConfig', { root: true });
  }

  if (to.matched.some((record) => record.meta.onlyNotLoggedIn)) {
    if (
      store.getters['currentUserStorage/isLoggedIn'] &&
      Date.now() <= store.getters['currentUserStorage/getCurrentUser']?.refreshExpiry
    ) {
      next('/');
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.onlyLoggedIn)) {
    checkGuestExpiry();
    if (
      store.getters['currentUserStorage/isLoggedIn'] &&
      Date.now() <= store.getters['currentUserStorage/getCurrentUser']?.refreshExpiry
    ) {
      store.dispatch('currentUserStorage/updateCredentials');
      if (
        store.getters['currentUserStorage/getCurrentUser']?.roles?.some((role: Role) => (to.meta?.roles as Role[])?.includes(role))
      ) {
        next();
      } else {
        if (store.getters['currentUserStorage/isGuest']) {
          next({ name: Route.DOCUMENTS });
        } else if (store.getters['currentUserStorage/isHr'] || store.getters['currentUserStorage/isProcurement']) {
          next({ name: Route.ORGANIZATIONAL_MAP });
        } else {
          next('/');
        }
      }
    } else {
      store.dispatch('currentUserStorage/logout');
      next({ name: Route.LOGIN });
    }
  } else {
    if (!store.getters['currentUserStorage/isLoggedIn']) {
      next({ name: Route.LOGIN });
    } else {
      next();
    }
  }
});

export default router;
