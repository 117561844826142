import { ConfirmationType } from '@/model/enums/confirmation-type';
import { DateRange } from '@/model/enums/date-range';
import { Prescriber } from '@/model/enums/prescriber';
import { State } from '@/model/enums/state';
import RequirementFilterOptions from '@/model/filter/requirement-filter-options';
import RequirementType from '@/model/requirement-type';
import { placesFilterStorage } from '@/store/modules/places-filter-storage';
import { toolFilterStorage } from '@/store/modules/tool-filter-storage';
import { personFilterStorage } from '@/store/modules/person-filter-storage';
import { Module } from 'vuex';

type FilterStorageModule = Module<
  {
    requirementFilterOptions: RequirementFilterOptions;
    requirementSearchText: string;
    adminRequirementsSearchText: string;
    adminUsersSearchText: string;
    adminTypesSearchText: string;
    adminBasicInformationOptionsSearchText: string;
    documentSearchText: string;
    basicInformationSearchText: string;
    isFiltering: boolean;
  },
  unknown
>;

export default {
  namespaced: true,
  modules: { placesFilterStorage, toolFilterStorage, personFilterStorage },
  state: {
    requirementFilterOptions: new RequirementFilterOptions(),
    requirementSearchText: '' as string,
    adminRequirementsSearchText: '' as string,
    adminUsersSearchText: '' as string,
    adminTypesSearchText: '' as string,
    adminBasicInformationOptionsSearchText: '' as string,
    documentSearchText: '' as string,
    basicInformationSearchText: '' as string,
    isFiltering: false,
  },
  mutations: {
    setRequirementFilterOptions(
      state: { requirementFilterOptions: RequirementFilterOptions },
      payload: RequirementFilterOptions,
    ) {
      state.requirementFilterOptions = payload;
    },
    clearRequirementFilterOptions(state: { requirementFilterOptions: RequirementFilterOptions }) {
      state.requirementFilterOptions = new RequirementFilterOptions();
    },
    setPrescriberFilter(state: { requirementFilterOptions: RequirementFilterOptions }, payload: Prescriber[]) {
      state.requirementFilterOptions.prescribers = payload;
    },
    setTypeFilter(state: { requirementFilterOptions: RequirementFilterOptions }, payload: RequirementType) {
      state.requirementFilterOptions.type = payload;
    },
    setConfirmationTypeFilter(
      state: { requirementFilterOptions: RequirementFilterOptions },
      payload: ConfirmationType | undefined,
    ) {
      state.requirementFilterOptions.confirmationType = payload;
    },
    setStateFilter(state: { requirementFilterOptions: RequirementFilterOptions }, payload: State | undefined) {
      state.requirementFilterOptions.state = payload;
    },
    setStartDateFilter(state: { requirementFilterOptions: RequirementFilterOptions }, payload: string) {
      state.requirementFilterOptions.startDate = payload;
    },
    setEndDateFilter(state: { requirementFilterOptions: RequirementFilterOptions }, payload: string) {
      state.requirementFilterOptions.endDate = payload;
    },
    setDateRange(state: { requirementFilterOptions: RequirementFilterOptions }, payload: DateRange) {
      state.requirementFilterOptions.dateRange = payload;
    },
    setRequirementSearchText(state: { requirementFilterOptions: RequirementFilterOptions }, payload: string) {
      state.requirementFilterOptions.text = payload;
    },
    setAdminUsersSearchText(state: { adminUsersSearchText: string }, payload: string) {
      state.adminUsersSearchText = payload;
    },
    setAdminTypesSearchText(state: { adminTypesSearchText: string }, payload: string) {
      state.adminTypesSearchText = payload;
    },
    setAdminRequirementsSearchText(state: { adminRequirementsSearchText: string }, payload: string) {
      state.adminRequirementsSearchText = payload;
    },
    setAdminBasicInformationOptionsSearchText(
      state: { adminBasicInformationOptionsSearchText: string },
      payload: string,
    ) {
      state.adminBasicInformationOptionsSearchText = payload;
    },
    setDocumentSearchText(state: { documentSearchText: string }, payload: string) {
      state.documentSearchText = payload;
    },
    setBasicInformationSearchText(state: { basicInformationSearchText: string }, payload: string) {
      state.basicInformationSearchText = payload;
    },
    setFiltering(state: { isFiltering: boolean }, payload: boolean) {
      state.isFiltering = payload;
    },
  },
  getters: {
    getRequirementFilterOptions(state: { requirementFilterOptions: RequirementFilterOptions }) {
      return state.requirementFilterOptions;
    },
    getRequirementSearchText(state: { requirementFilterOptions: RequirementFilterOptions }) {
      return state.requirementFilterOptions.text;
    },
    getAdminUsersSearchText(state: { adminUsersSearchText: string }) {
      return state.adminUsersSearchText;
    },
    getAdminTypesSearchText(state: { adminTypesSearchText: string }) {
      return state.adminTypesSearchText;
    },
    getAdminRequirementsSearchText(state: { adminRequirementsSearchText: string }) {
      return state.adminRequirementsSearchText;
    },
    getAdminBasicInformationOptionsSearchText(state: { adminBasicInformationOptionsSearchText: string }) {
      return state.adminBasicInformationOptionsSearchText;
    },
    getDocumentSearchText(state: { documentSearchText: string }) {
      return state.documentSearchText;
    },
    getBasicInformationSearchText(state: { basicInformationSearchText: string }) {
      return state.basicInformationSearchText;
    },
    isFiltering(state: { isFiltering: boolean }) {
      return state.isFiltering;
    },
  },
} as FilterStorageModule;
