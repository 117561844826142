import { PlaceTrait } from '@/model/enums/place-trait';
import PlaceFilterOptions from '@/model/filter/place-filter-options';

export default class PlaceFilterRequest {
  public text: string;
  public types: string | undefined;
  public active: boolean | undefined;
  public dangerousActivities: boolean | undefined;
  public placeTrait: PlaceTrait | undefined;
  public operationalUnitId?: number;

  constructor(text: string, filterOptions: PlaceFilterOptions) {
    this.text = text;
    this.types = filterOptions.types && filterOptions.types.length > 0 ? filterOptions.types.join(',') : undefined;
    this.active = filterOptions.active;
    this.dangerousActivities = filterOptions.dangerousActivities;
    this.placeTrait = filterOptions.placeTrait;
  }

  public typesToArray(): {
    text: string;
    types: string[] | undefined;
    active: boolean | undefined;
    dangerousActivities: boolean | undefined;
    placeTrait: PlaceTrait | undefined;
  } {
    return {
      ...this,
      types: this.types ? this.types.split(',') : undefined,
    };
  }
}
