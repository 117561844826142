import axios from 'axios';
import Business from '@/model/business';
import Pageable from '@/model/page/pageable';
import PageOptions from '@/model/page/page-options';
import { Commit, Dispatch } from 'vuex';
import { HttpStatus } from '@/model/enums/http-status';
import { i18n } from '@/i18n';
import { Composer } from 'vue-i18n';
import BusinessType from '@/model/business-type';
import { findBusiness } from '@/helper/methods';
import { PlaceRequest } from '@/model/request/place-request';
import OperationalUnitFilterRequest from '@/model/request/operational-unit-filter-request';
import { Module } from 'vuex';
import PlaceFilterRequest from '@/model/request/filter/place-filter-request';

const { t }: Composer = i18n.global;

type BusinessStorageModule = Module<
  {
    businesses: Business[];
    pageableBusinesses: Pageable<Business> | object;
    pageableOperationalUnitBusinesses: Pageable<Business> | object;
    pageableSubUnits: Pageable<Business> | object;
    businessType: BusinessType[];
  },
  unknown
>;

export default {
  namespaced: true,
  state: {
    businesses: [],
    pageableBusinesses: {},
    pageableOperationalUnitBusinesses: {},
    pageableSubUnits: {},
    businessType: [],
  },
  mutations: {
    setBusinesses(state: { businesses: Business[] }, payload: Business[]) {
      state.businesses = payload;
    },
    setPageableBusinesses(state: { pageableBusinesses: Pageable<Business> }, payload: Pageable<Business>) {
      if (payload.currentPage === 0) {
        state.pageableBusinesses = payload;
      } else {
        state.pageableBusinesses.items = state.pageableBusinesses.items.concat(payload.items);
        state.pageableBusinesses.currentPage = payload.currentPage;
      }
    },
    setPageableOperationalUnitBusinesses(
      state: { pageableOperationalUnitBusinesses: Pageable<Business> },
      payload: Pageable<Business>,
    ) {
      if (payload.currentPage === 0) {
        state.pageableOperationalUnitBusinesses = payload;
      } else {
        state.pageableOperationalUnitBusinesses.items = state.pageableOperationalUnitBusinesses.items.concat(
          payload.items,
        );
        state.pageableOperationalUnitBusinesses.currentPage = payload.currentPage;
      }
    },
    setPageableSubUnits(state: { pageableSubUnits: Pageable<Business> }, payload: Pageable<Business>) {
      if (payload.currentPage === 0) {
        state.pageableSubUnits = payload;
      } else {
        state.pageableSubUnits.items = state.pageableSubUnits.items.concat(payload.items);
        state.pageableSubUnits.currentPage = payload.currentPage;
      }
    },
    setBusiness(
      state: {
        pageableOperationalUnitBusinesses: Pageable<Business>;
        pageableSubUnits: Pageable<Business>;
      },
      payload: Business,
    ) {
      const pageableBusinesses = new Pageable<Business>(state.pageableOperationalUnitBusinesses);
      const mainBusiness = pageableBusinesses.getById(payload.id);
      if (mainBusiness) {
        pageableBusinesses.setById(payload.id, payload);
      } else {
        const modifiedBusiness = findBusiness(state.pageableOperationalUnitBusinesses.items, payload.id);
        if (modifiedBusiness) {
          Object.assign(modifiedBusiness, payload);
        } else {
          if (payload.mainId) {
            const modifiedMainBusiness = findBusiness(state.pageableOperationalUnitBusinesses.items, payload.mainId);
            if (modifiedMainBusiness) {
              modifiedMainBusiness.units = modifiedMainBusiness.units
                ? modifiedMainBusiness.units.concat(payload)
                : [payload];
            }
            state.pageableSubUnits.items = state.pageableSubUnits?.items
              ? state.pageableSubUnits.items.concat(payload)
              : [payload];
          } else {
            pageableBusinesses.addItem(payload);
          }
        }
      }
      state.pageableOperationalUnitBusinesses = pageableBusinesses;
    },
    removePlace(
      state: {
        pageableOperationalUnitBusinesses: Pageable<Business>;
      },
      payload: Business,
    ) {
      const pageableBusinesses = new Pageable<Business>(state.pageableOperationalUnitBusinesses);
      if (payload.mainId) {
        const modifiedMainBusiness = findBusiness(state.pageableOperationalUnitBusinesses.items, payload.mainId);
        if (modifiedMainBusiness && modifiedMainBusiness.units) {
          const deletedId = modifiedMainBusiness.units.findIndex((business: Business) => business.id === payload.id);
          if (deletedId >= 0) {
            modifiedMainBusiness.units.splice(deletedId, 1);
          }
        }
      } else {
        pageableBusinesses.removeById(payload.id);
      }
    },
    setBusinessTypes(state, payload: BusinessType[]) {
      state.businessType = payload;
    },
  },
  actions: {
    getBusinesses({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
      return axios
        .get('/businesses')
        .then(({ data }) => {
          if (data) {
            commit('setBusinesses', data);
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getBusinessById({ commit, dispatch }, businessId: number) {
      return axios
        .get(`/businesses/${businessId}`)
        .then(({ data }) => {
          if (data) {
            commit('setOpenedBusiness', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getOperationalUnitBusinessPage(
      { commit, dispatch, rootGetters },
      { pageOptions, operationalUnitId }: { pageOptions: PageOptions; operationalUnitId: number },
    ) {
      const text = rootGetters['filterStorage/getBasicInformationSearchText'];
      const filterOptions = rootGetters['filterStorage/placesFilterStorage/getPlaceFilterOptions'];

      const params = new PlaceFilterRequest(text, filterOptions);

      return axios
        .get(`/operational-units/${operationalUnitId}/places`, { params: { ...params, ...pageOptions } })
        .then(({ data }) => {
          if (data) {
            commit('setPageableOperationalUnitBusinesses', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getAllBusinessesPage(
      { commit, dispatch, rootGetters }: { commit: Commit; dispatch: Dispatch; rootGetters: any },
      pageOptions: PageOptions,
    ) {
      const text = rootGetters['filterStorage/getBasicInformationSearchText'];
      return axios
        .get(`/businesses/page`, { params: { ...pageOptions, text } })
        .then(({ data }) => {
          if (data) {
            commit('setPageableBusinesses', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    save({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, formData: FormData) {
      return axios
        .post('/businesses', formData)
        .then((response) => {
          if (response.status !== HttpStatus.OK_BUT_NOT_FILTERED || response.data.mainId) {
            commit('setBusiness', response.data);
          }
          dispatch(
            'showSuccessNotification',
            {
              message: response.data.mainId
                ? t('notification.success.business-unit-addition')
                : t('notification.success.business-addition'),
            },
            { root: true },
          );
          return response.data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    modify({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, request: PlaceRequest) {
      return axios
        .put(`/businesses/${request.id}`, request)
        .then((response) => {
          if (response.status === HttpStatus.OK_BUT_NOT_FILTERED) {
            commit('removePlace', response.data);
          } else {
            commit('setBusiness', response.data);
          }
          dispatch(
            'showSuccessNotification',
            {
              message: t('notification.success.business-modification', {
                name: response.data.name,
              }),
            },
            { root: true },
          );
          return response.data;
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    delete({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }, business: Business) {
      return axios
        .delete(`/businesses/${business.id}`)
        .then(() => {
          commit('removePlace', business);
          dispatch(
            'showSuccessNotification',
            {
              message: t('notification.success.business-deletion', {
                name: business.name,
              }),
            },
            { root: true },
          );
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getTypes({ commit, dispatch }: { commit: Commit; dispatch: Dispatch }) {
      return axios
        .get('/business-types')
        .then(({ data }) => {
          if (data) {
            commit('setBusinessTypes', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getNextId({ dispatch }: { dispatch: Dispatch }, mainUnitId: number | null) {
      return axios
        .get('/businesses/next-id', { params: { mainUnitId } })
        .then(({ data }) => {
          if (data) {
            return data;
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
    getSubUnitsPageable(
      { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
      {
        id,
        pageOptions,
        filterOptions,
      }: { id: number; pageOptions: PageOptions; filterOptions: OperationalUnitFilterRequest },
    ) {
      return axios
        .get(`/businesses/${id}/units-pageable`, { params: { ...pageOptions, ...filterOptions } })
        .then(({ data }) => {
          if (data) {
            commit('setPageableSubUnits', data);
          }
        })
        .catch((error) => {
          dispatch('defaultErrorMessage', error, { root: true });
        });
    },
  },
  getters: {
    getBusinesses(state: { businesses: Business[] }) {
      return state.businesses;
    },
    getPageableBusinesses(state: { pageableBusinesses: Pageable<Business> }) {
      return state.pageableBusinesses;
    },
    getPageableOperationalUnitBusinesses(state: { pageableOperationalUnitBusinesses: Pageable<Business> }) {
      return state.pageableOperationalUnitBusinesses;
    },
    getPageableSubUnits(state: { pageableSubUnits: Pageable<Business> }) {
      return state.pageableSubUnits;
    },
    getBusinessTypes(state: { businessType: BusinessType[] }) {
      return state.businessType;
    },
  },
} as BusinessStorageModule;
