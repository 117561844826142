export default {
  state: {
    dashboardPanels: new Array<number>(),
    dashboardSubPanels: new Array<number[]>(),
    documentPanels: new Array<number>(),
    documentSubPanels: new Array<number[]>(),
  },
  mutations: {
    setDashboardPanels(state: { dashboardPanels: number[] }, payload: number[]) {
      state.dashboardPanels = payload;
    },
    setDashboardSubPanels(state: { dashboardSubPanels: number[][] }, payload: number[][]) {
      state.dashboardSubPanels = payload;
    },
    setDocumentPanels(state: { documentPanels: number[] }, payload: number[]) {
      state.documentPanels = payload;
    },
    setDocumentSubPanels(state: { documentSubPanels: number[][] }, payload: number[][]) {
      state.documentSubPanels = payload;
    },
    setPropertyStorageItemsCount(state: { propertyStorageItemsCount: number }, payload: number) {
      state.propertyStorageItemsCount = payload;
    },
    increaseOrDecreasePropertyStorageItems(state: { propertyStorageItemsCount: number }, isIncreasing: boolean) {
      isIncreasing ? state.propertyStorageItemsCount++ : state.propertyStorageItemsCount--;
    },
  },
  getters: {
    getDashboardPanels(state: { dashboardPanels: number[] }) {
      return state.dashboardPanels;
    },
    getDashboardSubPanels(state: { dashboardSubPanels: number[][] }) {
      return state.dashboardSubPanels;
    },
    getDocumentPanels(state: { documentPanels: number[] }) {
      return state.documentPanels;
    },
    getDocumentSubPanels(state: { documentSubPanels: number[][] }) {
      return state.documentSubPanels;
    },
  },
};
