import { ConfirmationType } from '@/model/enums/confirmation-type';
import { State } from '@/model/enums/state';
import moment from 'moment';
import { DateRange } from '@/model/enums/date-range';
import RequirementFilterOptions from '@/model/filter/requirement-filter-options';
import { FILTER_DATE_FORMAT } from '@/constants/date-format';
import { Prescriber } from '@/model/enums/prescriber';

export default class RequirementFilterRequest {
  public categoryName: string;
  public prescribers: string | Prescriber[];
  public typeId: number | undefined;
  public confirmationType: ConfirmationType | undefined;
  public state: State | undefined;
  public startDate: string;
  public endDate: string;
  public text: string;

  constructor(categoryName: string, filterOptions: RequirementFilterOptions) {
    this.categoryName = categoryName;
    this.prescribers = filterOptions.prescribers;
    this.typeId = filterOptions.type?.id ?? undefined;
    this.confirmationType = filterOptions.confirmationType ?? undefined;
    this.state = filterOptions.state ?? undefined;
    if (filterOptions.dateRange) {
      this.startDate =
        filterOptions.dateRange === DateRange.LAST_WEEK
          ? moment()
              .subtract(7, 'd')
              .format(FILTER_DATE_FORMAT)
          : moment()
              .subtract(30, 'd')
              .format(FILTER_DATE_FORMAT);
      this.endDate = moment().format(FILTER_DATE_FORMAT);
    } else {
      this.startDate = filterOptions.startDate;
      this.endDate = filterOptions.endDate;
    }
    this.text = filterOptions.text;
  }
}
