import Notification from '@/model/notification';
import Swal from 'sweetalert2';
import { i18n } from '@/i18n';
import { Composer } from 'vue-i18n';
import { AuthenticationStatus } from '@/model/enums/authentication-status';

const { t, te }: Composer = i18n.global;

export default {
  actions: {
    showErrorNotification({ commit }: any, notification: Notification) {
      Swal.fire({
        title: notification.title,
        html: notification.message,
        icon: 'error',
        showCancelButton: false,
        position: 'center',
        confirmButtonColor: '#2778c4',
        showClass: {
          icon: '',
        },
      });
    },
    showWarningNotification({ commit }: any, notification: Notification) {
      return Swal.fire({
        title: notification.title,
        html: notification.message,
        icon: 'warning',
        showCancelButton: false,
        position: 'center',
        confirmButtonColor: '#2778c4',
        showClass: {
          icon: '',
        },
      }).then((data) => {
        return data.isConfirmed || data.isDismissed;
      });
    },
    showSuccessNotification({ commit }: any, notification: Notification) {
      return Swal.fire({
        title: notification.title,
        html: notification.message,
        icon: 'success',
        showCancelButton: false,
        position: 'center',
        confirmButtonColor: '#2778c4',
        showClass: {
          icon: '',
        },
      }).then((data) => {
        return data.isConfirmed || data.isDismissed;
      });
    },
    showInfoNotification({ commit }: any, notification: Notification) {
      Swal.fire({
        title: notification.title,
        html: notification.message,
        icon: 'info',
        showCancelButton: false,
        position: 'center',
        confirmButtonColor: '#2778c4',
        showClass: {
          icon: '',
        },
      });
    },
    showConfirmNotification({ commit }: any, notification: Notification) {
      return Swal.fire({
        title: notification.title,
        html: notification.message,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('notification.confirm.yes').toString(),
        cancelButtonText: t('notification.confirm.no').toString(),
        confirmButtonColor: '#2778c4',
        showClass: {
          icon: '',
        },
        position: 'center',
      }).then((data) => {
        return data.isConfirmed;
      });
    },
    defaultErrorMessage({ dispatch }: any, message: string) {
      if (message && te(message)) {
        dispatch('showErrorNotification', { message: t(message) });
      } else if (message !== AuthenticationStatus.GUEST_EXPIRED) {
        dispatch('showErrorNotification', { message: t('notification.error.default') });
      }
    },
  },
};
